import { useState, useEffect } from 'react';

// Helpers
import { Link, useNavigate } from 'react-router-dom';

// @mui material components
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

//  example components
import DataTable from 'examples/Tables/DataTable';
import DefaultCell from 'layouts/dashboards/components/DefaultCell';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import typography from '../../../assets/theme/base/typography';

// Redux
import { useSelector } from 'react-redux';
import api from 'api';
import _ from 'lodash';
import Loader from '../../../components/Loader';

// Assets
import EchelonBackgroundSVG from 'assets/images/pursuingmyechelon.svg';
import LookupButtonSVG from 'assets/images/look-up-button-01.svg';
import LeaderBoardSVG from 'assets/images/echelon-leaderboard.svg';

// Icons
import MountainBikingIcon from 'assets/images/mountainbike-01.svg';
import RoadCyclingIcon from 'assets/images/roadcycling-01.svg';
import TrailRunningIcon from 'assets/images/trailrunning-01.svg';
import RoadRunningIcon from 'assets/images/roadrunning-01.svg';
import SwimRunIcon from 'assets/images/swimrun-01.svg';
import ObstacleCourseIcon from 'assets/images/obstaclecourse-01.svg';
import SwimIcon from 'assets/images/swim-01.svg';

const Echelon = () => {
  // Get user for dashboard data
  const { size } = typography;
  const user = useSelector((state) => state.user);
  const token = user.data.token;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [activeButton, setActiveButton] = useState(0);
  const [eventResults, setEventResults] = useState({ columns: [], rows: [] });

  const handleClick = (event) => {
    setActiveButton(event.target.id);
  };

  const generateIcon = (discipline) => {
    switch (discipline) {
      case 'Road Running':
        return RoadRunningIcon;
      case 'Road Cycling':
        return RoadCyclingIcon;
      case 'Trail Running':
        return TrailRunningIcon;
      case 'Mountain Biking':
        return MountainBikingIcon;
      case 'Swimrun':
        return SwimRunIcon;
      case 'Swim':
        return SwimIcon;
      default:
        return ObstacleCourseIcon;
    }
  };

  const generateActiveDisciplineEvents = (index) => {
    const eventData = user.data.userParticipation[index];

    if (user.data.userParticipation[index].Average_RI__c === 100) {
      return '';
    }

    const columns = [
      { Header: '', accessor: 'Name', width: '70%' },
      { Header: '', accessor: 'RI_Race_Index__c', width: '20%' },
      {
        Header: '',
        accessor: 'Details',
        width: '10%',
        align: 'right',
      },
    ];

    let rows = [
      {
        Name: (
          <DefaultCell>
            {eventData.Best_RI_Event__c.replace(/(<([^>]+)>)/gi, '')}
          </DefaultCell>
        ),
        RI_Race_Index__c: (
          <DefaultCell>
            {Math.floor(eventData.Best_RI_Aged_Race_Index__c)}
          </DefaultCell>
        ),

        Details: (
          <IconButton
            className="base-button"
            edge="start"
            color="inherit"
            onClick={() => {
              return navigate('/dashboard/my-results', {
                state: {
                  eventName: eventData.Best_RI_Event__c.replace(
                    /(<([^>]+)>)/gi,
                    ''
                  ),
                  echelon: true,
                  distanceName: eventData.Best_RI_Distance__c,
                },
              });
            }}
            aria-label="open"
            sx={{
              padding: 0,
              marginTop: -0.5,
              marginRight: 1,
              marginLeft: 0,
            }}
          >
            <img width={30} src={LookupButtonSVG} />
          </IconButton>
        ),
      },
      {
        Name: eventData.X2nd_Best_RI_Aged_Race_Index__c ? (
          <DefaultCell>
            {eventData.X2nd_Best_RI_Event__c.replace(/(<([^>]+)>)/gi, '')}
          </DefaultCell>
        ) : (
          ''
        ),
        RI_Race_Index__c: eventData.X2nd_Best_RI_Aged_Race_Index__c ? (
          <DefaultCell>
            {Math.floor(eventData.X2nd_Best_RI_Aged_Race_Index__c)}
          </DefaultCell>
        ) : (
          ''
        ),
        Details: eventData.X2nd_Best_RI_Aged_Race_Index__c ? (
          <IconButton
            className="base-button"
            edge="start"
            color="inherit"
            onClick={() => {
              return navigate('/dashboard/my-results', {
                state: {
                  eventName: eventData.X2nd_Best_RI_Event__c.replace(
                    /(<([^>]+)>)/gi,
                    ''
                  ),
                  echelon: true,
                  distanceName: eventData.Best_RI_Distance__c,
                },
              });
            }}
            aria-label="open"
            sx={{
              padding: 0,
              marginTop: -0.5,
              marginRight: 1,
              marginLeft: 0,
            }}
          >
            <img width={30} src={LookupButtonSVG} />
          </IconButton>
        ) : (
          ''
        ),
      },

      {
        Name: eventData.X3rd_Best_RI_Aged_Race_Index__c ? (
          <DefaultCell>
            {eventData.X3rd_Best_RI_Event__c.replace(/(<([^>]+)>)/gi, '')}
          </DefaultCell>
        ) : (
          ''
        ),
        RI_Race_Index__c: eventData.X3rd_Best_RI_Aged_Race_Index__c ? (
          <DefaultCell>
            {Math.floor(eventData.X3rd_Best_RI_Aged_Race_Index__c)}
          </DefaultCell>
        ) : (
          ''
        ),
        Details: eventData.X3rd_Best_RI_Aged_Race_Index__c ? (
          <IconButton
            className="base-button"
            edge="start"
            color="inherit"
            onClick={() => {
              return navigate('/dashboard/my-results', {
                state: {
                  eventName: eventData.X3rd_Best_RI_Event__c.replace(
                    /(<([^>]+)>)/gi,
                    ''
                  ),
                  echelon: true,
                  distanceName: eventData.X3rd_Best_RI_Aged_Race_Index__c,
                },
              });
            }}
            aria-label="open"
            sx={{
              padding: 0,
              marginTop: -0.5,
              marginRight: 1,
              marginLeft: 0,
            }}
          >
            <img width={30} src={LookupButtonSVG} />
          </IconButton>
        ) : (
          ''
        ),
      },
    ];

    rows = _.reject(rows, (o) => {
      return o.Name === '';
    });

    // const rows = [eventData].map((item) => {
    //   return {
    //     Name: item.Name ? (
    //       <DefaultCell>{removeDateFromName(item.Name)}</DefaultCell>
    //     ) : (
    //       ''
    //     ),
    //     Start_Date__c: item.Start_Date__c ? (
    //       <DefaultCell>{item.Start_Date__c}</DefaultCell>
    //     ) : (
    //       ''
    //     ),
    //     // Discipline__c: (
    //     //   <DisciplineCell
    //     //     eventLink={item.Entry_Link__c ? item.Entry_Link__c : ''}
    //     //     image={item.Discipline__c}
    //     //   />
    //     // ),
    //   };
    // });

    return (
      <MDBox className="active-discipline-events">
        <DataTable
          table={{
            columns,
            rows,
          }}
          entriesPerPage={false}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
        />
      </MDBox>
    );
  };

  const generateEventsResults = () => {
    const columns = [
      { Header: '', accessor: 'Name', width: '50%' },
      { Header: '', accessor: 'Date__c', width: '25%' },
      { Header: '', accessor: 'Distance_Name__c', width: '25%' },
      { Header: '', accessor: 'Details', width: '5%' },
    ];

    const rows = eventResults.map((item) => {
      return {
        Name: item.Event_Name__c
          ? removeDateFromName(item.Event_Name__c.replace(/(<([^>]+)>)/gi, ''))
          : item.Event_Name_Report__c,
        Date__c: item.Date__c,
        Distance_Name__c: item.Distance_Name__c,
        Details: (
          <DefaultCell>
            <DetailsDialog data={item} />
          </DefaultCell>
        ),
      };
    });

    setEventResults({
      columns,
      rows,
    });

    setLoading(false);
  };

  const generateDisciplineButtons = () => {
    return (
      <MDBox className="pursuitIndexButtons">
        {user.data.userParticipation.map((dis, index) => {
          if (user.data.userParticipation[index].Average_RI__c === 100) {
            return '';
          }
          return (
            <>
              <MDBox display="flex" alignItems="start" justifyContent="center">
                <button
                  className={activeButton == index ? 'active' : undefined}
                  key={index}
                  id={index}
                  onClick={handleClick}
                >
                  {dis.Discipline__c}
                </button>

                <img
                  width={25}
                  id={`pursuitIndex-leaderboard-${index}`}
                  style={{ marginLeft: 10, marginTop: 3 }}
                  src={LeaderBoardSVG}
                  onClick={() => {
                    return navigate('/dashboard/leaderboard', {
                      state: {
                        discipline: dis.Discipline__c,
                      },
                    });
                  }}
                />
                <img
                  width={30}
                  src={LookupButtonSVG}
                  style={{ marginLeft: 10, marginTop: 0 }}
                  onClick={() => {
                    return navigate('/dashboard/leaderboard', {
                      state: {
                        discipline: dis.Discipline__c,
                      },
                    });
                  }}
                />
              </MDBox>

              {activeButton == index && generateActiveDisciplineEvents(index)}
            </>
          );
        })}
      </MDBox>
    );
  };

  const generateDisciplineDetails = (iconIndex) => {
    const activeDiscipline = user.data.userParticipation[iconIndex];

    return (
      <MDBox
        sx={{
          width: '100%',
          height: '320px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 1.5rem',
          mb: 4,
          mt: 11,
          marginBottom: 10,
        }}
      >
        <img
          style={{ marginBottom: '20px' }}
          width={50}
          src={generateIcon(activeDiscipline.Discipline__c)}
        />

        {/* {activeDiscipline.Category__r ? (
          <MDTypography
            sx={{
              mt: 0.5,
              textAlign: 'center',
              width: '100%',
              textTransform: 'uppercase',
              fontSize: '1rem',
              fontweight: 300,
              fontFamily: 'TriakisFont-SemiBold',
              color: '#000',
            }}
            variant="h6"
            color="primary"
          >
            {activeDiscipline.Category__r.Name}
          </MDTypography>
        ) : (
          <MDTypography
            sx={{
              mt: 0,
              textAlign: 'center',
              width: '100%',
              textTransform: 'uppercase',
              fontSize: '1rem',
              fontweight: 300,
              fontFamily: 'TriakisFont-SemiBold',
              color: '#000',
              lineHeight: '2',
            }}
            variant="h6"
            color="primary"
          >
            &nbsp;
          </MDTypography>
        )} */}
        {activeDiscipline.Average_RI__c && (
          <>
            <MDTypography
              sx={{
                mb: -1,
                mt: 0,
                textAlign: 'center',
                width: '100%',
                textTransform: 'uppercase',
                fontSize: '4rem',
                fontWeight: 300,
                lineHeight: '0.8',
                fontFamily: 'TriakisFont-Heavy',
                color: '#000',
              }}
              variant="h6"
              color="primary"
            >
              {Math.floor(activeDiscipline.Average_RI__c)}
            </MDTypography>
            <MDTypography
              sx={{
                mt: 0,
                mb: 1,
                textAlign: 'center',
                width: '100%',
                textTransform: 'uppercase',
                fontSize: '1rem',
                fontWeight: 300,
                fontFamily: 'TriakisFont-SemiBold',
                color: '#000',
              }}
              variant="h6"
              color="primary"
            >
              PURSUIT INDEX
            </MDTypography>
          </>
        )}
      </MDBox>
    );
  };

  if (loading) {
    return (
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox
              pt={50}
              px={3}
              style={{
                display: 'flex',

                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Loader />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  return (
    <>
      <MDBox>
        <MDTypography
          sx={{
            mb: 1,
            mt: 2,
            textAlign: 'center',
            width: '70%',
            fontWeight: 300,
            lineHeight: '1.5',
            margin: '0 auto',
            fontSize: { xs: size.xs, sm: size.sm, md: size.md, lg: size.lg },
          }}
          variant="body2"
          color="secondary"
        >
          Peer-to-peer performance.
          <br />
          Your pursuit index is based on real verified event results only.
          <img
            width={25}
            src={LookupButtonSVG}
            style={{ marginLeft: 5, marginTop: 0, verticalAlign: 'middle' }}
            onClick={() => {
              return navigate('/dashboard/about');
            }}
          />
        </MDTypography>
        <MDBox
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <MDBox
            sx={{
              width: '100%',
              height: '320px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0',
              mb: 2,
            }}
          >
            <MDBox
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${EchelonBackgroundSVG})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              {/* <img
              width={50}
              src={generateIcon(data.Distance__r.Discipline__c)}
            /> */}
              {generateDisciplineDetails(activeButton)}
              {/* {data.Category__r && (
              <MDTypography
                sx={{
                  mt: 0.5,
                  textAlign: 'center',
                  width: '100%',
                  textTransform: 'uppercase',
                  fontSize: '1rem',
                  fontweight: 300,
                  fontFamily: 'TriakisFont-SemiBold',
                  color: '#000',
                }}
                variant="h6"
                color="primary"
              >
                {data.Category__r.Name}
              </MDTypography>
            )} */}
              {/* {data.Actual_Time_in_Text__c && (
              <MDTypography
                sx={{
                  mb: 0,
                  mt: 0,
                  textAlign: 'center',
                  width: '100%',
                  textTransform: 'uppercase',
                  fontSize: '2rem',
                  fontweight: 300,
                  lineHeight: '1',
                  fontFamily: 'TriakisFont-Heavy',
                  color: '#000',
                }}
                variant="h6"
                color="primary"
              >
                {data.Actual_Time_in_Text__c}
              </MDTypography>
            )}
            {data.Position_Category__c && (
              <>
                <MDTypography
                  sx={{
                    textAlign: 'center',
                    width: '100%',
                    mb: 0,
                    textTransform: 'uppercase',
                    fontSize: '0.6rem',
                    fontweight: 300,
                    fontFamily: 'TriakisFont-SemiBold',
                    color: '#000',
                  }}
                  variant="h6"
                  color="primary"
                >
                  Category Position
                </MDTypography>
                <MDTypography
                  sx={{
                    mt: 0,
                    textAlign: 'center',
                    width: '100%',
                    textTransform: 'uppercase',
                    fontSize: '0.8rem',
                    fontweight: 300,
                    fontFamily: 'TriakisFont-Heavy',
                    color: '#000',
                  }}
                  variant="h6"
                  color="primary"
                >
                  {data.Position_Category__c}
                </MDTypography>
              </>
            )} */}
            </MDBox>
          </MDBox>
          <MDBox
            sx={{
              width: { xs: '100%', md: '50%' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0 1rem',
              mb: { xs: 4, md: 0 },
              mt: { xs: 0, md: 4 },
            }}
          >
            <MDBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {generateDisciplineButtons()}
            </MDBox>
          </MDBox>
          {noResultsFound && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDBox
                  pt={2}
                  px={3}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    No Results Found
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          )}
        </MDBox>
      </MDBox>
    </>
  );
};

export default Echelon;
