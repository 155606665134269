import { useState, useEffect } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';

// @mui material components
import Loader from 'components/Loader';

// formik components
import { Formik, Form } from 'formik';

import { thunkUpdateUserDetails } from 'actions/userActions';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';

//  example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

// Assets
import PursuitLogo from 'assets/images/pursuitlogoloading-01.svg';

import { useSelector, useDispatch } from 'react-redux';

// Returning layout schemas for form and form fields
import validations from './components/schemas/validations';
import form from './components/schemas/form';
// import initialValues from "./components/schemas/initialValues";
import ProfileForm from './components/ProfileForm';
import checkout from './components/schemas/form';

const Overview = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const token = user.data.token;
  const { userInfo } = user.data.data;
  const { formId, formField } = form;
  const [phoneValue, setPhoneValue] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const {
    formField: { firstName, lastName, idNumber, passportNumber, email },
  } = checkout;

  const [loading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={errorMsg}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success"
      content="You're profile has been updated."
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  useEffect(() => {}, [user]);

  const initialValues = {
    [firstName.name]: user.data.data.firstName,
    [lastName.name]: user.data.data.lastName,
    [idNumber.name]: user.data.data.idNumber,
    [passportNumber.name]: user.data.data.passportNumber,
    [email.name]: user.data.data.email,
  };

  const submitForm = async (values, actions) => {
    actions.setSubmitting(false);
  };

  const handleSubmit = async (values, actions) => {
    setLoading(true);

    dispatch(
      thunkUpdateUserDetails(token, {
        firstName: values.firstName,
        lastName: values.lastName,
        idNumber: values.idNumber,
        passportNumber: values.passportNumber,
        email: values.email.toLowerCase(),
        mobileNumber: phoneValue,
      })
    ).then((res) => {
      if (!res.data.success) {
        setLoading(false);

        setErrorMsg(res.data.data.error);
        openErrorSB();
      }
      if (res.data.success) {
        setLoading(false);
        openSuccessSB();
        submitForm(values, actions);
      }
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      {renderErrorSB}
      {renderSuccessSB}
      <DashboardNavbar userName />

      {/* <NavTabs page="profile" /> */}

      <MDBox
        mt={0}
        px={3}
        style={{
          background: '#222222',
        }}
      >
        <Grid container mt={2}>
          <MDBox
            component="img"
            src={PursuitLogo}
            justifyContent="center"
            sx={{ margin: '0 auto 20px', display: { lg: 'none' } }}
            alt="Pursuit"
            width="60%"
          />
          {/* <Grid item xs={12} md={6} xl={4}>
            Move to prefrences <PlatformSettings />
          </Grid> */}
          <Grid item xs={12} md={12} lg={12}>
            {/* <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} /> */}
            <Formik
              initialValues={initialValues}
              validationSchema={validations}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <ProfileForm
                      phoneInitialValue={
                        user.data.data.mobileNumber
                          ? user.data.data.mobileNumber.toString()
                          : ''
                      }
                      onPhoneValue={(phone) => setPhoneValue(phone)}
                      // disabled={disabled}
                      formData={{
                        values,
                        touched,
                        formField,
                        errors,
                      }}
                    />

                    <MDBox
                      mt={3}
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <MDButton
                        disabled={isSubmitting}
                        type="submit"
                        variant="outlined"
                        color="info"
                        fullWidth
                      >
                        Update Profile
                      </MDButton>
                    </MDBox>

                    {/* {!disabled && (
                      <MDBox
                        mt={3}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <MDButton
                          disabled={isSubmitting}
                          type="submit"
                          variant="outlined"
                          color="info"
                          fullWidth
                        >
                          Update Profile
                        </MDButton>
                      </MDBox>
                    )} */}
                  </MDBox>
                  {/* {disabled && (
                    <MDBox mt={3} mb={4} textAlign="center">
                      <MDButton
                        onClick={() => setDisabled(!disabled)}
                        variant="outlined"
                        color="info"
                      >
                        Edit Profile
                      </MDButton>
                    </MDBox>
                  )} */}
                  {!disabled && (
                    <MDBox mt={0} mb={4} textAlign="center">
                      <MDTypography
                        onClick={() => setDisabled(!disabled)}
                        variant="button"
                        color="secondary"
                      >
                        Cancel
                      </MDTypography>
                    </MDBox>
                  )}
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Overview;
