// User Actions
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const RECEIVED_LOGGED_IN_USER = 'RECEIVED_LOGGED_IN_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const RECEIVED_UPDATED_USER = 'RECEIVED_UPDATED_USER';
export const SEND_SUPPORT_EMAIL = 'SEND_SUPPORT_EMAIL';
export const SEND_SUPPORT_EMAIL_ERROR = 'SEND_SUPPORT_EMAIL_ERROR';
export const SEND_SUPPORT_EMAIL_SUCCESS = 'SEND_SUPPORT_EMAIL_SUCCESS';
