import { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { useReactPWAInstall } from 'react-pwa-install';

// @mui material components
import Grid from '@mui/material/Grid';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DetailsDialog from './DetailsDialog';

//  example components
import DataTable from 'examples/Tables/DataTable';
import DefaultCell from 'layouts/dashboards/components/DefaultCell';

// Redux
import { useSelector } from 'react-redux';

import api from 'api';
import _ from 'lodash';
import Loader from '../../../components/Loader';

const PersonalResults = () => {
  // Get user for dashboard data
  const user = useSelector((state) => state.user);
  const token = user.data.token;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [previousEventData, setPreviousEventData] = useState([]);
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const [echelonEvent, setEchelonEvent] = useState({});

  const [participationResultData, setParticipationResultData] = useState({
    columns: [],
    rows: [],
  });

  const fetchPreviousEvents = async () => {
    setLoading(true);
    const data = await api.get(`/events/results?type=personal`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const events = data.data.data;

    if (events.length === 0) {
      setLoading(false);
      setNoResultsFound(true);
    } else {
      if (location.state?.echelon) {
        let findEvent = events.filter((item) => {
          let itemName = location.state?.event
            ? removeDateFromName(
                item.Event_Name__c.replace(/(<([^>]+)>)/gi, '')
              )
            : item.Event_Name_Report__c;
          return itemName === location.state.eventName;
        });

        setEchelonEvent(findEvent[0]);
      }

      setPreviousEventData(events);
      // TODO Move to later
      // if (supported() && !isInstalled()) {
      //   pwaInstall({
      //     title: 'Install Pursuit App',
      //     logo: PursuitIcon,
      //     description: 'Get the latest updates on your results.',
      //   });
      // }
    }
  };

  const removeDateFromName = (str) => {
    return str.slice(5);
  };

  useEffect(() => {
    if (previousEventData.length === 0 && !noResultsFound) {
      fetchPreviousEvents();
    }
  }, []);

  const generateEventsResults = () => {
    const columns = [
      { Header: '', accessor: 'Name', width: '50%' },
      { Header: '', accessor: 'Date__c', width: '25%' },
      { Header: '', accessor: 'Distance_Name__c', width: '25%' },
      { Header: '', accessor: 'Details', width: '5%' },
    ];

    const rows = previousEventData.map((item) => {
      return {
        Name: item.Event_Name__c
          ? removeDateFromName(item.Event_Name__c.replace(/(<([^>]+)>)/gi, ''))
          : item.Event_Name_Report__c,
        Date__c: item.Date__c,
        Distance_Name__c: item.Distance_Name__c,
        Details: (
          <DefaultCell>
            <DetailsDialog data={item} />
          </DefaultCell>
        ),
      };
    });

    setParticipationResultData({
      columns,
      rows,
    });

    setLoading(false);
  };

  useEffect(() => {
    if (previousEventData.length > 0) {
      generateEventsResults();
    }
  }, [previousEventData]);

  if (loading) {
    return (
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox
              pt={50}
              px={3}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Loader />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  return (
    <MDBox>
      {!_.isEmpty(echelonEvent) && (
        <DetailsDialog isOpen={true} data={echelonEvent} />
      )}

      {participationResultData.rows.length > 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox py={1} px={2}>
              <DataTable
                canSearch
                getPageIndex={(pageIndex) => setPage(pageIndex)}
                table={participationResultData}
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={false}
                noEndBorder
              />
            </MDBox>
          </Grid>
        </Grid>
      )}
      {noResultsFound && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox
              pt={2}
              px={3}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <MDTypography variant="h6" color="secondary" fontWeight="light">
                Results
              </MDTypography>
              <MDTypography variant="h5" fontWeight="medium">
                No Results Found For {user.data.data.firstName}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      )}
    </MDBox>
  );
};

export default PersonalResults;
