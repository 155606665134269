//  base styles
import colors from "assets/theme-dark/base/colors";
import borders from "assets/theme-dark/base/borders";

//  helper functions
import pxToRem from "assets/theme-dark/functions/pxToRem";
import breakpoints from "../base/breakpoints";

const { background } = colors;
const { borderRadius } = borders;

export default {
  styleOverrides: {
    root: {
      width: pxToRem(250),
      whiteSpace: "nowrap",
      border: "none",
    },

    paper: {
      width: pxToRem(250),
      backgroundColor: background.sidenav,
      height: `calc(100vh - ${pxToRem(32)})`,
      margin: 0,
      borderRadius: borderRadius.xl,
      border: "none",
    },

    paperAnchorDockedLeft: {
      borderRight: "none",
    },
  },
};
