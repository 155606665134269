import * as Yup from 'yup';
import checkout from './form';

const {
  formField: {
    firstName,
    lastName,
    email,
    repeatEmail,
    password,
    repeatPassword,
  },
} = checkout;

export default Yup.object().shape({
  [firstName.name]: Yup.string().required(firstName.errorMsg),
  [lastName.name]: Yup.string().required(lastName.errorMsg),
  [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
  [repeatEmail.name]: Yup.string()
    .required(repeatEmail.errorMsg)
    .oneOf([Yup.ref('email'), null], repeatEmail.invalidMsg),
  [password.name]: Yup.string()
    .required(password.errorMsg)
    .min(6, password.invalidMsg),
  [repeatPassword.name]: Yup.string()
    .required(repeatPassword.errorMsg)
    .oneOf([Yup.ref('password'), null], repeatPassword.invalidMsg),
});
