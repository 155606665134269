//  example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import UpcomingEventsList from './UpcomingEventsList';

const Riders = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <UpcomingEventsList />
    </DashboardLayout>
  );
};

export default Riders;
