//  example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import AllResults from './AllResults';

const Results = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AllResults />
    </DashboardLayout>
  );
};

export default Results;
