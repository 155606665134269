import api from '../api';
import { store, persistor } from '../store';

import {
  LOGIN_USER,
  LOGIN_USER_ERROR,
  RECEIVED_LOGGED_IN_USER,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  RECEIVED_UPDATED_USER,
  SEND_SUPPORT_EMAIL,
  SEND_SUPPORT_EMAIL_ERROR,
  SEND_SUPPORT_EMAIL_SUCCESS,
} from './actionTypes';

const loginUser = () => {
  return {
    type: LOGIN_USER,
  };
};

const recievedLoggedInUser = (user, token) => {
  return {
    type: RECEIVED_LOGGED_IN_USER,
    data: {
      ...user.data,
      ...user.userInfo,
      token,
    },
  };
};

const loginUserError = (error) => {
  return {
    type: LOGIN_USER_ERROR,
    data: error.response,
  };
};

export const getCurrentUser = async (token) => {
  return api
    .get('/auth/currentuser', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const forgetPasswordRequest = async (email) => {
  return api
    .post('/auth/forgotPassword', email)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return {
        success: false,
        error: error.message,
      };
    });
};

export const resetPasswordRequest = async (resettoken, password) => {
  return api
    .put(`/auth/resetpassword/${resettoken}`, {
      password: password,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateUserPassword = async (data) => {
  return api
    .put(
      '/auth/updatepassword',
      {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteAccount = async (token) => {
  return api
    .delete(`/auth/deleteuser`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const logout = async () => {
  await persistor.purge();
  return api
    .get('/auth/logout')
    .then(() => {
      return window.location.reload();
    })
    .catch((error) => {
      return error;
    });
};

export const thunkLoginUser = (userCredentials, callback) => {
  store.dispatch(loginUser());
  return async (dispatch) => {
    try {
      const res = await api.post(
        '/auth/login',
        JSON.stringify(userCredentials)
      );
      let user = await getCurrentUser(res.data.token);
      return dispatch(recievedLoggedInUser(user, res.data.token));
    } catch (error) {
      return dispatch(loginUserError(error));
    }
  };
};

const updateUser = () => {
  return {
    type: UPDATE_USER,
  };
};

const receivedUpdatedUser = (user, token) => {
  return {
    type: RECEIVED_UPDATED_USER,
    data: {
      ...user.data,
      ...user.userInfo,
      token,
    },
  };
};

const updatedUserError = (error) => {
  return {
    type: UPDATE_USER_ERROR,
    data: error.response,
  };
};

export const thunkUpdateUserDetails = (token, user) => {
  return async (dispatch) => {
    try {
      const res = await api.put('/auth/updatedetails', JSON.stringify(user), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let updatedUser = await getCurrentUser(token);
      return dispatch(receivedUpdatedUser(updatedUser, token));
    } catch (error) {
      return dispatch(updatedUserError(error));
    }
  };
};

// Admin Actions
export const getAllUsers = async (token) => {
  return api
    .get('/users', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const sendSupportEmailError = (error) => {
  return {
    type: SEND_SUPPORT_EMAIL_ERROR,
    data: error.response,
  };
};

const supportEmail = () => {
  return {
    type: SEND_SUPPORT_EMAIL,
  };
};

const supportEmailReceived = () => {
  return {
    type: SEND_SUPPORT_EMAIL_SUCCESS,
    data: {
      success: true,
    },
  };
};

export const thunkSendSupportEmail = (data) => {
  store.dispatch(supportEmail());
  return async (dispatch) => {
    try {
      const res = await api.post('/auth/support', data);
      if (res.status === 200) {
        return res;
      }
      // return dispatch(supportEmailReceived());
    } catch (error) {
      return dispatch(sendSupportEmailError(error));
    }
  };
};
