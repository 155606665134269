import React, { useState, useEffect } from 'react';

// react-router-dom components
import { useLocation } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Helper Libraries
import api from 'api';
import _ from 'lodash';

import Select, { components } from 'react-select';

// @mui material components
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Typography from '@mui/material/Typography';

// Icons
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// Pursuit components
import MDBox from 'components/MDBox';
import Loader from '../../../components/Loader';
import MDTypography from 'components/MDTypography';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

// Data components
import DataTable from 'examples/Tables/DataTable';

// Select Input Styles
const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
  }),
  option: (provided, state) => ({
    ...provided,
    background: '#000000',
    color: state.isSelected ? '#6fe3ed' : '#ffffff',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#ffffff',
    fontSize: '10px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    color: '#ffffff',
    fontSize: '10px',
    padding: 0,
  }),

  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),

  control: (provided) => ({}),
  menu: (provided, state) => ({
    ...provided,
    color: '#ffffff',
    fontSize: '10px',
  }),
  menuList: (provided, state) => ({
    color: '#ffffff',
    fontSize: '10px',
  }),
  singleValue: (provided, state) => {
    return {
      ...provided,
      color: state.isSelected ? '#FFEC39' : '#FFEC39',
      fontSize: '10px',
    };
  },
};

// Select Input Control Component
const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <MDBox
        sx={{
          display: 'flex',
          borderRadius: 1.5,
          alignItems: 'center',
          pt: 0.5,
          pb: 0.5,
          pl: 1,
          pr: 1,
          fontFamily: 'MontserratRegular',
          border: '2px dotted #6fe3ed',
        }}
      >
        {/* <MDTypography fontSize="12px" variant="p" color="primary">
          Filter
        </MDTypography> */}
        <ArrowRightIcon color="secondary" fontSize="medium" />
        {children}
      </MDBox>
    </components.Control>
  );
};

const WhosParticipating = () => {
  // Get user for dashboard data
  const user = useSelector((state) => state.user);
  const token = user.data.token;

  // Use location for param checking
  const location = useLocation();

  // Set loading state while data is fetching
  const [loading, setLoading] = useState(false);

  const [noResultsFound, setNoResultsFound] = useState(false);
  const [previousEventData, setPreviousEventData] = useState([]);
  const [noFilterResults, setNoFilterResults] = useState(false);
  // Filter values
  const [selectedGenderCategory, setSelectedGenderCategory] = useState({
    label: 'All Genders',
    value: 'all',
  });

  const [selectedCategory, setSelectedCategory] = React.useState({
    label: 'All Category',
    value: 'all',
  });

  const [selectedBatch, setSelectedBatch] = React.useState({
    label: 'Batch',
    value: 'all',
  });

  const [categories, setCategories] = useState([]);
  const [genderCategories, setGenderCategories] = useState([]);
  const [batchCategories, setBatchCategories] = useState([]);

  const [participationResultData, setParticipationResultData] = useState({
    columns: [],
    rows: [],
  });

  const getGenderFirstChar = (gender) => {
    if (gender.charAt(0) === 'F') {
      return 'W';
    }
    return gender.charAt(0);
  };

  const fetchPreviousEvents = async () => {
    setLoading(true);
    const data = await api.get(`/events/distance-participants`, {
      params: {
        distanceId: location.state.distanceId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const dataWithMissingNamesRemoved = data.data.data.filter(
      (item) => item.Contact_Full_Name__c
    );
    const events = dataWithMissingNamesRemoved;

    // Build Category List
    const uniqueCategories = [
      ...new Set(
        events.map((item) => item.Category__r && item.Category__r.Name)
      ),
    ];

    let categoriesStripped = uniqueCategories.filter((item) => item !== null);
    let categoriesMapped = categoriesStripped.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    categoriesMapped.unshift({
      label: 'All Categories',
      value: 'all',
    });

    // Build Batch List
    const uniqueBatches = [
      ...new Set(
        events.map((item) => item.Actual_Batch__r && item.Actual_Batch__r.Name)
      ),
    ];

    let batchesStripped = uniqueBatches.filter((item) => item !== null);
    let batchesMapped = batchesStripped.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    batchesMapped.unshift({
      label: 'Batch',
      value: 'all',
    });

    if (events.length === 0) {
      setLoading(false);
      setNoResultsFound(true);
    } else {
      setCategories(categoriesMapped);
      setBatchCategories(batchesMapped);
      setGenderCategories([
        {
          label: 'All Genders',
          value: 'all',
        },
        {
          label: 'Men',
          value: 'Male',
        },
        {
          label: 'Women',
          value: 'Female',
        },
      ]);

      setPreviousEventData(events);
    }
  };

  const generateEventsResults = () => {
    const columns = [
      {
        Header: '',
        accessor: 'Contact_Full_Name__c',
        width: '50%',
      },
      {
        Header: '',
        accessor: 'Gender__c',
        width: '20%',
      },
      {
        Header: '',
        accessor: 'Category__r',
        width: '30%',
      },
      {
        Header: '',
        accessor: 'Actual_Batch__r',
        width: '5%',
      },
    ];

    const rows = previousEventData.map((item) => {
      return {
        Event_Name_Report__c: item.Event_Name_Report__c,
        Contact_Full_Name__c: item.Contact_Full_Name__c,
        Gender__c: item.Contact__r?.Gender__c
          ? `${getGenderFirstChar(item.Contact__r.Gender__c)} ${
              item.Position_Gender__c ? `(${item.Position_Gender__c})` : ''
            }`
          : '',
        Category__r: item.Category__r
          ? `${item.Category__r.Name} ${
              item.Position_Category__c ? `(${item.Position_Category__c})` : ''
            }`
          : '',
        Actual_Batch__r: item.Actual_Batch__r ? item.Actual_Batch__r.Name : '',
      };
    });

    setParticipationResultData({
      columns,
      rows,
    });

    setLoading(false);
  };

  useEffect(() => {
    if (previousEventData.length === 0 && !noResultsFound) {
      fetchPreviousEvents();
    }
    if (previousEventData.length > 0) {
      generateEventsResults();
    }
  }, [previousEventData]);

  const changeCategory = () => {
    const columns = [
      {
        Header: '',
        accessor: 'Contact_Full_Name__c',
        width: '50%',
      },
      {
        Header: '',
        accessor: 'Gender__c',
        width: '20%',
      },
      {
        Header: '',
        accessor: 'Category__r',
        width: '30%',
      },
      {
        Header: '',
        accessor: 'Actual_Batch__r',
        width: '5%',
      },
    ];

    let categoryData = [];

    if (
      (selectedCategory.value === 'all' ||
        selectedCategory.value == undefined) &&
      (selectedGenderCategory.value === 'all' ||
        selectedGenderCategory.value == undefined)
    ) {
      categoryData = previousEventData;
    }

    if (
      selectedCategory.value !== 'all' &&
      selectedGenderCategory.value !== 'all'
    ) {
      categoryData = previousEventData.filter(
        (item) =>
          item.Category__r?.Name === selectedCategory.value &&
          item.Contact__r?.Gender__c === selectedGenderCategory.value
      );
    }

    if (
      (selectedCategory.value === 'all' ||
        selectedCategory.value == undefined) &&
      selectedGenderCategory.value !== 'all'
    ) {
      categoryData = previousEventData.filter(
        (item) => item.Contact__r?.Gender__c === selectedGenderCategory.value
      );
    }

    if (
      selectedCategory.value !== 'all' &&
      (selectedGenderCategory.value === 'all' ||
        selectedGenderCategory.value == undefined)
    ) {
      categoryData = previousEventData.filter(
        (item) => item.Category__r?.Name === selectedCategory.value
      );
    }

    if (categoryData.length > 0) {
      const rows = categoryData.map((item) => {
        return {
          Event_Name_Report__c: item.Event_Name_Report__c,
          Contact_Full_Name__c: item.Contact_Full_Name__c,
          Gender__c: item.Contact__r?.Gender__c
            ? `${getGenderFirstChar(item.Contact__r.Gender__c)} ${
                item.Position_Gender__c ? `(${item.Position_Gender__c})` : ''
              }`
            : '',
          Category__r: item.Category__r
            ? `${item.Category__r.Name} ${
                item.Position_Category__c
                  ? `(${item.Position_Category__c})`
                  : ''
              }`
            : '',
          Actual_Batch__r: item.Actual_Batch__r
            ? item.Actual_Batch__r.Name
            : '',
        };
      });
      setParticipationResultData({
        columns,
        rows,
      });
    } else {
      const rows = previousEventData.map((item) => {
        return {
          Event_Name_Report__c: '',
          Contact_Full_Name__c: '',
          Gender__c: '',
          Category__r: '',
          Actual_Batch__r: '',
        };
      });

      setParticipationResultData({
        columns,
        rows,
      });
    }
  };

  useEffect(() => {
    if (previousEventData.length === 0) {
      fetchPreviousEvents();
    } else {
      changeCategory();
    }
  }, [selectedCategory, selectedGenderCategory]);

  if (loading) {
    return (
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox
              pt={50}
              px={3}
              style={{
                display: 'flex',

                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Loader />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar rideJoburgNavLogo />
      <MDBox id="distance-PARTICIPANTS">
        {participationResultData.rows.length > 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 1.5rem',
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    width: '100%',
                    mt: 3,
                    fontSize: '1.5rem',
                    fontWeight: 100,
                    fontFamily: 'MontserratItal',
                  }}
                  autoFocus
                  variant="h6"
                  color="#ffffff"
                >
                  {participationResultData.rows[0].Event_Name_Report__c}{' '}
                  {location.state.distanceName}
                </Typography>
              </MDBox>

              <MDBox py={1} px={2}>
                <MDBox display="flex" justifyContent="space-between">
                  <MDTypography
                    fontSize={12}
                    sx={{ mb: 1, mt: 2 }}
                    fontWeight="medium"
                  >
                    SEARCH
                  </MDTypography>
                  <MDTypography
                    fontSize={12}
                    sx={{ mb: 1, mt: 2 }}
                    fontWeight="medium"
                  >
                    WHO'S PARTICIPATING
                  </MDTypography>
                </MDBox>
                <Divider sx={{ mb: 2 }} variant="fullWidth" />
                <MDBox
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <MDBox
                    display="flex"
                    alignItems="center"
                    sx={{ width: '100%', mr: 1 }}
                  >
                    <Select
                      key={1}
                      styles={customStyles}
                      options={categories}
                      isSearchable={false}
                      value={selectedCategory[0]}
                      placeholder={'Categories'}
                      components={{ Control }}
                      onChange={setSelectedCategory}
                    />
                  </MDBox>

                  <MDBox
                    display="flex"
                    alignItems="center"
                    sx={{ width: '70%' }}
                  >
                    <Select
                      key={2}
                      styles={customStyles}
                      options={genderCategories}
                      isSearchable={false}
                      value={selectedGenderCategory[0]}
                      placeholder={'Genders'}
                      components={{ Control }}
                      onChange={setSelectedGenderCategory}
                    />
                  </MDBox>

                  <MDBox
                    display="flex"
                    alignItems="center"
                    sx={{ width: '70%' }}
                  >
                    <Select
                      key={3}
                      styles={customStyles}
                      options={batchCategories}
                      isSearchable={false}
                      value={selectedBatch[0]}
                      placeholder={'Batch'}
                      components={{ Control }}
                      onChange={setSelectedBatch}
                    />
                  </MDBox>
                </MDBox>

                <DataTable
                  table={participationResultData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                  canSearch
                />
                {noFilterResults && (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <MDBox
                        pt={2}
                        px={3}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <MDTypography variant="h5" fontWeight="medium">
                          No Results Found
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                )}
              </MDBox>
            </Grid>
          </Grid>
        )}

        {noResultsFound && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox
                pt={2}
                px={3}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <MDTypography variant="h5" fontWeight="medium">
                  No Results Found
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default WhosParticipating;
