import { useState, useEffect } from 'react';

// Helpers
import { useLocation, useNavigate } from 'react-router-dom';

// @mui material components
import Grid from '@mui/material/Grid';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import LookupButtonSVG from 'assets/images/look-up-button-01.svg';

//  example components
import DataTable from 'examples/Tables/DataTable';
import DefaultCell from 'layouts/dashboards/components/DefaultCell';

// Redux
import { useSelector } from 'react-redux';

import api from 'api';
import _ from 'lodash';
import Loader from '../../../components/Loader';

const DistanceResults = () => {
  // Get user for dashboard data
  const user = useSelector((state) => state.user);
  const token = user.data.token;
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [previousEventData, setPreviousEventData] = useState([]);

  const [participationResultData, setParticipationResultData] = useState({
    columns: [],
    rows: [],
  });

  const fetchPreviousEvents = async () => {
    setLoading(true);

    const data = await api.get(`/events/distances`, {
      params: {
        eventName: location.state.eventName,
      },

      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const events = data.data.data;

    // Map event names

    if (events.length === 0) {
      setLoading(false);
      setNoResultsFound(true);
    } else {
      setPreviousEventData(events);
    }
  };

  useEffect(() => {
    if (previousEventData.length === 0 && !noResultsFound) {
      fetchPreviousEvents();
    }
  }, []);

  const removeDateFromName = (str) => {
    return str.slice(5);
  };

  const generateEventsResults = () => {
    const columns = [
      // { Header: 'Dist Name', accessor: 'Name' },
      // { Header: 'Date', accessor: 'Date__c' },
      // { Header: 'Discipline', accessor: 'Discipline__c', width: '5%' },
      // { Header: '', accessor: 'Details', width: '5%' },

      { Header: '', accessor: 'Name' },
      { Header: '', accessor: 'Date__c' },
      { Header: '', accessor: 'Discipline__c' },
      { Header: '', accessor: 'Details', width: '5%' },
    ];

    const rows = previousEventData.map((item, index) => {
      return {
        Name: item.Name,
        Date__c: item.Date__c,
        Discipline__c: item.Discipline__c,
        Details: (
          <IconButton
            edge="start"
            color="inherit"
            id={`distance-result-icon-${index}`}
            onClick={() => {
              return navigate('/dashboard/distance-details', {
                state: {
                  distanceId: item.Id,
                  distanceName: item.Name,
                },
              });
            }}
            aria-label="open"
            sx={{
              padding: 0,
              marginTop: -0.5,
              marginRight: 1,
              marginLeft: 0,
            }}
          >
            <img width={30} src={LookupButtonSVG} />
          </IconButton>
        ),
      };
    });

    setParticipationResultData({
      columns,
      rows,
    });

    setLoading(false);
  };

  useEffect(() => {
    if (previousEventData.length > 0) {
      generateEventsResults();
    }
  }, [previousEventData]);

  if (loading) {
    return (
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox
              pt={50}
              px={3}
              style={{
                display: 'flex',

                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Loader />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox id="distance-results-table">
        {participationResultData.rows.length > 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 1.5rem',
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    width: '100%',
                    mt: 3,
                    fontSize: '1.5rem',
                    fontWeight: 100,
                    fontFamily: 'MontserratItal',
                  }}
                  autoFocus
                  variant="h6"
                  color="#ffffff"
                >
                  {removeDateFromName(location.state.eventName)}
                </Typography>
              </MDBox>

              <MDBox py={1} px={2}>
                <DataTable
                  table={participationResultData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                />
              </MDBox>
            </Grid>
          </Grid>
        )}
        {noResultsFound && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox
                pt={2}
                px={3}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <MDTypography
                  variant="h5"
                  fontWeight="light"
                  align="center"
                  fontFamily="MontserratItal"
                >
                  {removeDateFromName(location.state.eventName)}
                </MDTypography>
                <MDTypography variant="h5" fontWeight="medium">
                  No Results Found
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default DistanceResults;
