// @mui material components
import Container from '@mui/material/Container';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

function Content() {
  return (
    <>
      <MDBox mx={2} p={2} mb={0} textAlign="center">
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          PURSUIT DATA &amp; PRIVACY POLICY
        </MDTypography>
      </MDBox>
      <Container>
        <MDBox mx={1} p={1} mb={1} textAlign="left">
          <div>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Index
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    WELCOME
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    OUR PROMISE
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    PRIVACY POLICY
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    SCOPE OF THIS POLICY AND YOUR CONSENT
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    CHANGES TO THE PRIVACY NOTICE AND YOUR DUTY TO INFORM US OF
                    CHANGES
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    THIRD-PARTY LINKS
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    THE DATA WE COLLECT ABOUT YOU
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    HOW IS YOUR DATA COLLECTED?
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    HOW WE USE YOUR INFORMATION AND LEGAL BASIS FOR PROCESSING
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    SHARING &amp; DISCLOSING YOUR INFORMATION
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    INTERNATIONAL TRANSFERS
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    HOW LONG WE WILL KEEP YOUR INFORMATION
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    HOW WE SECURE YOUR INFORMATION
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    YOUR LEGAL RIGHTS
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    CONTACT US
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    DATA PROTECTION OFFICER
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    WELCOME
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Welcome to Pursuit’s data and privacy policy.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Pursuit respects your privacy and is committed to protecting
                your data. This privacy notice will inform you as to how we look
                after your data when you visit or use our website or
                applications (including but not limited to www.pursuit.africa)
                as well as any of our social media pages, including but not
                limited to Facebook, Twitter, and Instagram (collectively
                referred to as the Platforms), regardless of where you visit
                them from, and to inform you about your privacy rights and how
                the law protects you.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={2}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    OUR PROMISE
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We will comply with applicable privacy and data protection laws
                and regulatory frameworks.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We will only process your personal information to supply,
                improve and manage our services. Any additional or unusual uses
                of your information will be explained at the point of
                collection.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We will only send you marketing emails if you have permitted us
                to (and you will be allowed to unsubscribe from each of our
                emails).
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We will enable you to opt out of usage tracking where possible;
                we track your use of our services to help us to tailor our
                content and advertising and to improve our services. We will not
                use information that identifies you personally, but if you
                prefer, you may opt-out here.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We will not share personally identifiable information with
                unrelated third parties for their use without your consent.
                However, we may share your information to provide the best
                customer experience across our brands.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We will take appropriate steps to safeguard your information
                following industry standards, but please remember that no system
                can be guaranteed 100% secure.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We will only keep your information for as long as we need it:
                (i) to provide you with a product/service; (ii) to improve our
                services; and (iii) for legal and audit purposes.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We will not actively collect personal information from those
                under 18s unless relevant consent from a competent person is
                obtained. We kindly ask that if you are under 18, you do not
                give us information about yourself or others.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We will give you control over the information we hold about you
                by enabling you to access, update and delete your information in
                line with your legal rights.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We will take steps to ensure that our suppliers and licensees
                look after your information as we do, including where they are
                processing your information in countries that do not provide the
                same legal protection to your information.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={3}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    PRIVACY POLICY
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                In providing you with our services and through your interactions
                with us, Pursuit may collect information about you and your use
                of our services. We are committed to being transparent in
                handling personal information, and we endeavour to comply with
                applicable privacy and data protection laws in the territories
                we operate. This policy describes the types of information we
                might collect, how we may use it and the circumstances in which
                we might disclose or transfer it.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                If you have any questions or comments about this policy or our
                approach to privacy, please contact us at info@pursuit.africa.
                We aim to reply to any queries within three working days.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={4}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    SCOPE OF THIS POLICY AND YOUR CONSENT
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                This policy governs our processing of your information in
                relation to the products and services we supply to you.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Please be aware that we also license our brands and content to
                other companies who may produce their websites, events and
                digital content. We take reasonable steps to ensure that these
                companies clearly identify themselves and handle your
                information appropriately.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                However, when you use any service which a third party operates,
                the use of your information will be subject to their privacy
                policy and not ours. If you have any concerns, please contact us
                at info@pursuit.africa.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Your use of our services constitutes your consent to processing
                the information described herein.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={5}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    CHANGES TO THE PRIVACY NOTICE AND YOUR DUTY TO INFORM US OF
                    CHANGES
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                It is important that the personal data we hold about you is
                accurate and current. Please keep us informed if your data
                changes during your relationship with us. Pursuit may update
                this policy at any time, so please check this page regularly to
                ensure you are up–to–date. We will notify you in advance of any
                significant changes in these terms and conditions which affect
                our ongoing relationship with you materially.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={6}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    THIRD-PARTY LINKS
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                The Platforms may include links to third-party websites,
                plug-ins and applications. Clicking on those links or enabling
                those connections may allow third parties to collect or share
                your data. We do not control these third-party websites and are
                not responsible for their privacy statements. When you leave our
                Platform, we encourage you to read the privacy notice on every
                website you visit.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={7}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    THE DATA WE COLLECT ABOUT YOU
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Personal data, or personal information means any information
                about an individual from which that person can be identified. It
                does not include data where the identity has been removed
                (anonymous data). We may collect, use, store and transfer
                different personal data about you, which we have grouped as
                follows:
              </span>
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <br />
                <br />
              </span>
            </p>
            <ul
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'disc',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    Identity Data includes first name, last name, username or
                    similar identifier, title, date of birth and gender.
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'disc',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    Contact Data includes billing address, delivery address,
                    email address and telephone numbers.
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'disc',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    Transaction Data includes details about payments to and from
                    you and other details of products and services you have
                    purchased from us.
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'disc',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    Technical Data includes internet protocol (IP) address, your
                    login data, browser type and version, time zone setting and
                    location, browser plug-in types and versions, operating
                    system and platform and other technology on the devices you
                    use to access the Platforms.
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'disc',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    Profile Data includes your username and password, purchases
                    or orders made by you, your interests, preferences, feedback
                    and survey responses.
                  </span>
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    <br />
                    <br />
                  </span>
                </p>
              </li>
            </ul>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Usage Data includes information about how you use our website,
                products and services.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Marketing and Communications Data includes your preferences in
                receiving marketing from us, any third parties (if applicable),
                and your communication preference.
              </span>
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <br />
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Information collected via social media and other third parties
                can include your name, profile picture, gender, e-mail address
                and date of birth. Please keep in mind that any information
                provided to us via a third party may also be subject to that
                third party’s privacy policy.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={8}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    HOW IS YOUR DATA COLLECTED?
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We use different methods to collect data from and about you,
                including through
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Direct interactions. You may give us your Identity, Contact,
                Profile and Marketing and Communications Data by filling in
                forms or by corresponding with us by post, phone, email or
                otherwise. This includes personal data you provide when you:
              </span>
            </p>
            <p>
              <br />
            </p>
            <ul
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'disc',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    create an account on and/or access our Platforms;
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'disc',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    sign up for an account and subscribe to our services or
                    publications;
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'disc',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    request marketing or newsletters to be sent to you;
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'disc',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    enter a competition, promotion or survey; or
                  </span>
                </p>
              </li>
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'disc',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    give us some feedback.
                  </span>
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                For purchases online, we may use third-party websites to
                facilitate such purchases. Your interactions with third-party
                platforms will be governed by the privacy terms of that third
                party, and we are not responsible for such interactions or for
                the additional personal information you may decide to provide to
                such third parties. You are strongly advised to read any
                relevant third party's privacy policies before any such
                interaction.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                This Website uses “cookies” to automatically collect information
                and data through the standard operation of the Internet servers.
                “Cookies” are small text files a website can use to recognise
                repeat users, facilitate the user’s ongoing access to and use of
                a website and allow a website to track usage behaviour and
                compile aggregate data that will allow the website operator to
                improve the functionality of the website and its content. The
                type of information collected by cookies is not used to identify
                you personally. If you do not want information collected through
                cookies, most browsers have a simple procedure that allows you
                to deny or accept the cookie feature. Please note, however, that
                cookies may be necessary to provide you with certain features on
                our Website. Thus if you disable the cookies on your browser,
                you may not be able to use those features, and your access to
                our Website will be limited.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={9}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    HOW WE USE YOUR INFORMATION AND LEGAL BASIS FOR PROCESSING
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We will use your information: (i) to deliver, improve and
                personalise our services to you following your preferences: and
                (ii) for our legitimate business purposes and needs.
                Specifically, we may use your information to:
              </span>
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <br />
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                administer your account(s);
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                send you service-related communications;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                respond to your requests and feedback;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                analyse and/or improve our services;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                personalise the way Pursuit content is presented to you online;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                deliver interest-based advertising based upon your browsing of
                our websites and others;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                prevent fraud or other potentially illegal activities (including
                copyright infringement);
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                block disruptive users and protect the safety of users of our
                services;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                enforce our terms of service;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                send you newsletters, offers and promotions relating to our
                brands and services and special promotions from our trusted
                partners following your email preferences; and
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                manage our email suppression list.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Where Pursuit proposes using your personal information for other
                uses, we will ensure that we notify you first. You will also be
                allowed to withhold or withdraw your consent for your use other
                than as listed above.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={10}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    SHARING &amp; DISCLOSING YOUR INFORMATION
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Information shared by Pursuit:
              </span>
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <br />
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                To improve your experience of our services and brands,
                information about your use of our services may be shared by
                Pursuit for research, analysis, and marketing.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Information shared with our suppliers:
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We use several third parties to perform business functions on
                our behalf, such as sending our newsletters, hosting our online
                services, providing our customer services and fulfilling
                confirmation of services and processing payments. We only
                disclose the information necessary to enable these third parties
                to perform their services. Our suppliers are contracted to
                comply with our instructions, and we require that they do not
                use your personal information for their business purposes.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Information shared with other parties:
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Generally, we will only disclose your data to other parties with
                your consent, for example, if you choose to share content via
                social media functionality. We may also disclose your personal
                information to law enforcement agencies, government/regulatory
                bodies, internet service providers and content protection
                organisations as required or allowed by law. For example,
                suppose you post inappropriate or objectionable content on our
                services or where we reasonably believe you may be in breach of
                the law. In that case, we may report your content and behaviour
                to relevant parties.
              </span>
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <br />
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Sale or transfer of all or part of our business or assets:
              </span>
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <br />
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We reserve the right to transfer your personal information in
                the event we sell or transfer all or a part of our business or
                assets so that the buyer can continue to offer you the online
                services. We will make reasonable efforts to inform you of the
                transfer of your information in such circumstances.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={11}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    INTERNATIONAL TRANSFERS
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                As described in the previous section, we may share your
                information with contracted third-party service providers. These
                companies may be located in, or use IT equipment located in;
                countries different to that in which your information is
                collected. Where this is the case, we will ensure that your
                information receives an equivalent level of protection.
              </span>
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <br />
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Whenever we transfer your data, we ensure a similar degree of
                protection is afforded to it.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={12}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    HOW LONG WE WILL KEEP YOUR INFORMATION?
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We will only retain your personal information on our systems for
                as long as is necessary for the relevant service or as long as
                it is set out in any relevant contract you hold with us.
                &nbsp;After this time, your personal information will either be
                securely deleted or anonymised to be used for research or
                statistical purposes.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={13}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    HOW WE SECURE YOUR INFORMATION
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We maintain appropriate organisational and technological
                safeguards to help protect against the loss, misuse or
                unauthorised access, disclosure, alteration or destruction of
                the information we hold about you. We also seek to ensure our
                service providers do the same. Unfortunately, no system can be
                guaranteed as completely secure, and there is an inherent risk
                in transmitting information over the internet. It may be
                possible for an unrelated party to intercept or access such
                transmissions unlawfully.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                If you believe that your personal information under our control
                has been compromised, including access to any accounts you hold
                with our services, please contact us immediately using the
                details below.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={14}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    YOUR LEGAL RIGHTS
                  </span>
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    <br />
                    <br />
                  </span>
                </p>
              </li>
            </ol>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                You have the following rights:
              </span>
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <br />
                <br />
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Request access to your data (commonly known as a “data subject
                access request”). This enables you to receive a copy of the
                personal data we hold about you and to check that we are
                lawfully processing it. &nbsp;If you would like to know what
                information we hold about you, you may submit a request to us by
                using the contact details below.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We will require you to prove your identity before granting
                access to your personal information. You will not have to pay a
                fee to access your data (or to exercise any of the other rights
                detailed below). However, we may be entitled to charge a
                reasonable fee if your request is unfounded, repetitive or
                excessive. Alternatively, we may refuse to comply with your
                request in these circumstances.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We will process your request within the timeframe required.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Request correction of the personal data that we hold about you.
                This enables you to have any incomplete or inaccurate data we
                hold about you corrected, though we may need to verify the
                accuracy of the new data you provide.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Request erasure (deletion) of your data. This enables you to ask
                us to delete or remove personal data where there is no good
                reason for us continuing to process it. You also have the right
                to ask us to delete or remove your data where you have
                successfully exercised your right to object to processing (see
                below), where we may have processed your information unlawfully
                or where we are required to erase your data to comply with local
                law. Note, however, that we may not always be able to comply
                with your request of erasure for specific legal reasons, which
                will be notified to you, if applicable, at the time of your
                request.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                If you would like us to delete your information from our
                records, please contact us using the details below, and we will
                respond within a reasonable time but within no more than 7
                working days. Please note that we may be required to retain
                certain information by law and/or for our own legitimate
                business purposes.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Object to processing of your data where we are relying on a
                legitimate interest (or those of a third party), and there is
                something about your particular situation which makes you want
                to object to processing on this ground as you feel it impacts
                your fundamental rights and freedoms. You also have the right to
                object where we are processing your data for direct marketing
                purposes. In some cases, we may demonstrate that we have
                compelling legitimate grounds to process your information which
                override your rights and freedoms.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Request restriction of processing of your data.&nbsp;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                This enables you to ask us to suspend the processing of your
                data in the following scenarios:&nbsp;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                (a) if you want us to establish the data’s accuracy;&nbsp;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                (b) where our use of the data is unlawful, but you do not want
                us to erase it;&nbsp;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                (c) where you need us to hold the data even if we no longer
                require it as you need it to establish, exercise or defend legal
                claims; or&nbsp;
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                (d) you have objected to our use of your data, but we need to
                verify whether we have overriding legitimate grounds to use it.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Request the transfer of your data to you or a third party. We
                will provide you, or a third party you have chosen, your data in
                a structured, commonly used, machine-readable format. Note that
                this right only applies to automated information you initially
                provided consent for us to use or where we used the information
                to perform a contract with you.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Withdraw consent at any time where we are relying on consent to
                process your data. However, this will not affect the lawfulness
                of any processing before you withdraw your consent. If you
                withdraw your consent, we may not be able to provide certain
                products or services to you. We will advise you if this is the
                case when you withdraw.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Communication Preferences and Opt-outs:
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                If you wish to stop receiving marketing communications from us,
                please click on the “unsubscribe” link at the bottom of the
                relevant newsletter and/or contact us directly to request us to
                do so. Please note that this will not unsubscribe you from all
                communications, only the newsletter in question and as such, you
                will need to perform this action for each of the newsletters you
                are subscribed to. If you wish to opt-out completely, please
                contact us using the details below.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Following industry standards, we reserve the right to retain a
                minimal amount of data necessary to ensure we do not contact you
                in the future.
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={15}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    CONTACT US
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                We have appointed a data protection officer (DPO) responsible
                for overseeing questions concerning this Privacy Policy. If you
                have any questions about this Privacy Policy, including any
                requests to exercise your legal rights, please contact the DPO
                using the details below.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                If you have any questions or comments about this policy or our
                approach to privacy, please contact us at
              </span>
            </p>
            <p>
              <br />
            </p>
            <ol
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: '20px',
              }}
              start={16}
            >
              <li
                aria-level={1}
                dir="ltr"
                style={{
                  listStyleType: 'decimal',
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre',
                }}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: '1.38',
                    marginTop: '0pt',
                    marginBottom: '0pt',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11pt',
                      fontFamily: 'Arial',
                      color: '#fff',
                      backgroundColor: 'transparent',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      fontVariant: 'normal',
                      textDecoration: 'none',
                      verticalAlign: 'baseline',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    DATA PROTECTION OFFICER
                  </span>
                </p>
              </li>
            </ol>
            <p>
              <br />
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Pursuit, Unit 6b, Graphic Centre, 5 Buiten Street, Cape Town,
                South Africa, 8001.
              </span>
            </p>
            <p
              dir="ltr"
              style={{
                lineHeight: '1.38',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Arial',
                  color: '#fff',
                  backgroundColor: 'transparent',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Email: info@pursuit.africa
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
          </div>
        </MDBox>
      </Container>
    </>
  );
}

export default Content;
