import { useState, useEffect } from 'react';

// react-router components
import { useLocation, useNavigate } from 'react-router-dom';

// helper methods
import { logout } from 'actions/userActions';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @material-ui core components
import { AppBar, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';

// Pursuit components
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';

//  example components
import NotificationItem from 'examples/Items/NotificationItem';

import PursuitIcon from 'assets/images/pursuit_icon_outline.svg';
import BackButtonSVG from 'assets/images/back-button-01.svg';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from 'examples/Navbars/DashboardNavbar/styles';

//  context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from 'context';

// Redux
import { useSelector } from 'react-redux';

function DashboardNavbar({
  absolute,
  light,
  isMini,
  backButton,
  userName,
  onBackClick,
  menu,
}) {
  const user = useSelector((state) => state.user);
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openNotificationMenu, setOpenNotificationMenu] = useState(false);
  const [openAccountMenu, setOpenAccountMenu] = useState(false);
  let route = useLocation().pathname.split('/').slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const logoutClick = () => {
    logout(() => {
      return window.location.reload();
    });
  };

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const handleOpenNotificationMenu = (event) =>
    setOpenNotificationMenu(event.currentTarget);
  const handleCloseNotificationMenu = () => setOpenNotificationMenu(false);
  const handleOpenAccountMenu = (event) =>
    setOpenAccountMenu(event.currentTarget);
  const handleCloseAccountMenu = () => setOpenAccountMenu(false);

  // Render the notifications menu
  const renderNotificationMenu = () => (
    <Menu
      anchorEl={openNotificationMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openNotificationMenu)}
      onClose={handleCloseNotificationMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>event</Icon>} title="upcoming events" />

      <NotificationItem icon={<Icon>info</Icon>} title="Announcement" />
    </Menu>
  );

  // Render the account menu
  const renderAccountMenu = () => (
    <Menu
      anchorEl={openAccountMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openAccountMenu)}
      onClose={handleCloseAccountMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        onClick={logoutClick}
        icon={<Icon>logout</Icon>}
        title="Logout"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: '#666',
    width: '100%',
    border: '1px dotted #666',
  });

  // Match function for routes
  const generateRoute = (route) => {
    let routeName = route[1].replace('-', ' ');
    if (routeName === 'distance results' || routeName === 'distance details') {
      routeName = 'results';
    }

    if (routeName === 'all results') {
      routeName = 'results';
    }

    if (routeName === 'events calendar') {
      routeName = 'events';
    }

    if (menu) {
      routeName = 'menu';
    }

    return routeName;
  };

  return (
    <AppBar
      position={'relative'}
      color="inherit"
      sx={(theme) =>
        navbar(theme, {
          transparentNavbar,
          absolute,
          light,
          darkMode,
          // backButton,
        })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {/* {!backButton && (
          <MDBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            <IconButton
              sx={navbarDesktopMenu}
              onClick={handleMiniSidenav}
              size="small"
              type="button"
              disableRipple
            >
              <Icon fontSize="small" sx={iconsStyle}>
                {miniSidenav ? 'menu_open' : 'menu'}
              </Icon>
            </IconButton>
          </MDBox>
        )} */}

        {!backButton && isMini ? null : (
          <MDBox
            sx={{ width: '100%' }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <MDBox
              style={{ display: 'flex', alignItems: 'center' }}
              color={light ? 'white' : 'inherit'}
              onClick={handleMiniSidenav}
            >
              {/* <IconButton
                size="small"
                disableRipple
                type="button"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
                style={{ display: 'flex' }}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton> */}

              <img
                src={PursuitIcon}
                width={40}
                style={{ marginLeft: 3, marginRight: 10 }}
              />
              {userName && (
                <Typography fontSize={14} color="primary">
                  {user?.data?.data?.firstName}
                </Typography>
              )}

              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                type="button"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenNotificationMenu}
              >
                <MDBadge badgeContent={9} color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
              </IconButton> */}
              {/* {renderNotificationMenu()} */}
            </MDBox>
            {route[1] && (
              <MDBox
                style={{ display: 'flex', alignItems: 'center' }}
                color={light ? 'white' : 'inherit'}
              >
                {/* <IconButton
                size="small"
                disableRipple
                type="button"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
                style={{ display: 'flex' }}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton> */}

                <Typography
                  fontSize={14}
                  style={{ fontFamily: '"MontserratBold"' }}
                  color="primary"
                >
                  {generateRoute(route)}
                </Typography>

                <img
                  id="back-navigator"
                  style={{ marginLeft: 2 }}
                  width={35}
                  onClick={() => (onBackClick ? onBackClick() : navigate(-1))}
                  src={BackButtonSVG}
                />
                {/* <IconButton
                  size="small"
                  disableRipple
                  type="button"
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={() => (onBackClick ? onBackClick() : navigate(-1))}
                >
                  <Icon sx={iconsStyle} fontSize="small">
                    close
                  </Icon>
                </IconButton> */}

                {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                type="button"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenNotificationMenu}
              >
                <MDBadge badgeContent={9} color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
              </IconButton> */}
                {/* {renderNotificationMenu()} */}
              </MDBox>
            )}
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
