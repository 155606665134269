//  example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import PersonalResults from './PersonalResults';

const MyResults = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar userName />
      <PersonalResults />
    </DashboardLayout>
  );
};

export default MyResults;
