export default {
  formId: 'user-login-form',
  formField: {
    email: {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      errorMsg: 'Email address is required.',
      invalidMsg: 'Your email address is invalid',
    },
    password: {
      name: 'password',
      label: 'Password',
      type: 'password',
      errorMsg: 'Password is required.',
      invalidMsg: 'Your password should be more than 6 characters.',
    },
  },
};
