import React, { useState } from 'react';

// react-router-dom components
import { Link } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Helper methods
import { forgetPasswordRequest } from '../../../actions/userActions';

// formik components
import { Formik, Form } from 'formik';

// Reset password layout schemas for form and form fields
import validations from './schemas/validations';
import form from './schemas/form';
import initialValues from './schemas/initialValues';
import ForgotPasswordForm from './ForgotPasswordForm';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import MDSnackbar from '../../../components/MDSnackbar';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Assets
import LogoWithText from 'assets/images/LogoWithText.svg';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={errorMsg}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Reset Link Sent"
      content="We have sent you a reset password link to your email. Please check your spam if you do not see any email from Pursuit."
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const { formId, formField } = form;

  const submitForm = async (values, actions) => {
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const handleSubmit = async (values, actions) => {
    setLoading(true);

    await forgetPasswordRequest({
      email: values.email,
    })
      .then((res) => {
        if (!res.data?.success) {
          setErrorMsg('User with this email does not exist.');
          openErrorSB();
        }

        if (res.data.success) {
          setLoading(false);
          submitForm(values, actions);
          openSuccessSB();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <BasicLayout>
      {renderSuccessSB}
      {renderErrorSB}

      <MDBox mx={2} p={2} mb={1} textAlign="center">
        <MDBox mx={2} p={2} mb={1} textAlign="center">
          <img src={LogoWithText} />
        </MDBox>

        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Forgot Password
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <Formik
          initialValues={initialValues}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form id={formId} autoComplete="off">
              <MDBox>
                <ForgotPasswordForm
                  formData={{
                    values,
                    touched,
                    formField,
                    errors,
                  }}
                />
                ;
                <MDBox
                  mt={2}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDButton
                    disabled={isSubmitting}
                    type="submit"
                    variant="outlined"
                    color="info"
                    fullWidth
                  >
                    Send Request
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Already have an account?&nbsp;&nbsp;
                  <MDTypography
                    component={Link}
                    to="/sign-in"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                  >
                    Login
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </Form>
          )}
        </Formik>
      </MDBox>
    </BasicLayout>
  );
};

export default ForgotPassword;
