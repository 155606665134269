import { useState } from 'react';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// react-router components
import { Link } from 'react-router-dom';

// @mui material components
import MenuItem from '@mui/material/MenuItem';

// Pursuit components
import MDBox from 'components/MDBox';

//  example components
import DefaultNavbarMenu from 'examples/Navbars/DefaultNavbar/DefaultNavbarMenu';

function AuthenticationMenu({ routes, open, close, mobileMenu }) {
  const renderAuthenticationMenuRoute = (routeName) =>
    routes.map(({ key, name, icon, collapse }) => {
      let template;

      const [menu, setMenu] = useState(false);
      const openMenu = ({ currentTarget }) => setMenu(currentTarget);
      const closeMenu = () => setMenu(false);

      if (key === routeName && !mobileMenu) {
        template = (
          <MenuItem key={key} onMouseEnter={openMenu} onMouseLeave={closeMenu}>
            {collapse.map(({ key: collapseKey, name: collapseName, route }) => (
              <MenuItem
                component={Link}
                to={route}
                key={collapseKey}
                onClick={mobileMenu ? undefined : close}
              >
                {name}
              </MenuItem>
            ))}
          </MenuItem>
        );
      } else if (key === routeName && mobileMenu) {
        template = (
          <MDBox key={key} px={2} mt={0} mb={2}>
            {collapse.map(({ key: collapseKey, name: collapseName, route }) => (
              <MenuItem
                component={Link}
                to={route}
                key={collapseKey}
                onClick={mobileMenu ? undefined : close}
              >
                {name}
              </MenuItem>
            ))}
          </MDBox>
        );
      }

      return template;
    });

  const renderMenuContent = (
    <MDBox display="block">
      {renderAuthenticationMenuRoute('sign-in')}
      {renderAuthenticationMenuRoute('sign-up')}
      {renderAuthenticationMenuRoute('reset-password')}
      {renderAuthenticationMenuRoute('forgot-password')}
      {renderAuthenticationMenuRoute('lock')}
      {renderAuthenticationMenuRoute('2-step-verification')}
      {renderAuthenticationMenuRoute('error')}
    </MDBox>
  );

  return mobileMenu ? (
    renderMenuContent
  ) : (
    <DefaultNavbarMenu open={open} close={close}>
      {renderMenuContent}
    </DefaultNavbarMenu>
  );
}

// Setting default values for the props of AuthenticationMenu
AuthenticationMenu.defaultProps = {
  mobileMenu: false,
  open: false,
  close: false,
};

// Typechecking props for the AuthenticationMenu
AuthenticationMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  close: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  mobileMenu: PropTypes.bool,
};

export default AuthenticationMenu;
