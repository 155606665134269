export default {
  formId: "user-profile-form",
  formField: {
    firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
    },
    lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
    },
    idNumber: {
      name: "idNumber",
      label: "RSA ID Number",
      type: "text",
    },
    passportNumber: {
      name: "passportNumber",
      label: "Passport Number",
      type: "text",
    },
    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
  },
};
