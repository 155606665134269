import React, { useState } from 'react';
// @mui material components
import Grid from '@mui/material/Grid';

// Helper methods
import { thunkSendSupportEmail } from 'actions/userActions';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Pursuit components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDSnackbar from 'components/MDSnackbar';

import ReCAPTCHA from 'react-google-recaptcha';

// @mui material components
import Loader from 'components/Loader';

// Preferences page components
import PursuitIcon from 'assets/images/pursuit_icon_outline.svg';
import PursuitLogo from 'assets/images/pursuitlogoloading-01.svg';
import ChangePassword from 'layouts/pages/account/preferences/components/ChangePassword';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import FormField from 'layouts/pages/users/new-user/components/FormField';
import { width } from '@mui/system';
import { useLocation } from 'react-router-dom';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// sendgrid.setApiKey(process.env.REACT_APP_SENDGRID_API_KEY);

const ContactPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const token = user?.data?.token;
  const [recaptcha, setRecaptcha] = useState('');
  const [isVerified, setIsVerified] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [fake_field, setFakeField] = useState('');
  const [loading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const location = useLocation();

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={errorMsg}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Thanks"
      content="Your message has been sent to our support team. We will be in touch shortly."
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const onChange = (value) => {
    setRecaptcha(value);
    if (value) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fake_field !== '') {
      return;
    }

    const emailData = {
      name,
      email,
      message,
    };

    setLoading(true);

    dispatch(thunkSendSupportEmail(emailData)).then((res) => {
      if (!res.data.success) {
        setLoading(false);
        setErrorMsg(res.data.data.error);
        openErrorSB();
      }
      if (res.data.success) {
        setLoading(false);
        setSuccessSB(true);
      }
    });

    // if (isVerified) {

    // }
  };

  if (loading) {
    return <Loader />;
  }

  if (location.pathname.includes('dashboard') && token) {
    return (
      <DashboardLayout>
        {renderSuccessSB}
        {renderErrorSB}
        <DashboardNavbar userName />

        <MDBox
          mt={0}
          px={3}
          style={{
            background: '#222222',
          }}
        >
          <Grid container mt={2}>
            <MDBox
              component="img"
              src={PursuitLogo}
              justifyContent="center"
              sx={{ margin: '0 auto 20px', display: { lg: 'none' } }}
              alt="Pursuit"
              width="60%"
            />
            <Grid item xs={12} lg={12}>
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: { xs: 'block', md: 'flex' },
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          sx={{ mr: { xs: 0, sm: 0, md: 2 } }}
                        >
                          <MDInput
                            type="text"
                            label="Name"
                            name="name"
                            id="name"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            // error={errors.password && touched.password}
                            // success={passwordV.length > 0 && !errors.password}
                            inputProps={{ autoComplete: '' }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          sx={{ mt: { xs: 2, md: 0 } }}
                        >
                          <MDInput
                            type="email"
                            label="Email"
                            id="email"
                            fullWidth
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            // error={errors.password && touched.password}
                            // success={passwordV.length > 0 && !errors.password}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} mt={2} mb={2}>
                        <MDInput
                          type="textarea"
                          label="Message"
                          id="message"
                          name="message"
                          multiline
                          rows={4}
                          fullWidth
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          // error={errors.password && touched.password}
                          // success={passwordV.length > 0 && !errors.password}
                        />
                      </Grid>

                      <MDBox
                        mt={1}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <MDButton
                          type="submit"
                          variant="outlined"
                          color="info"
                          fullWidth
                        >
                          Send Message
                        </MDButton>
                      </MDBox>
                      <MDBox
                        mt={1}
                        mb={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={onChange}
                          size="invisible"
                        />
                      </MDBox>
                      <input
                        type="hidden"
                        name="fake_field"
                        value={fake_field}
                        onChange={(e) => setFakeField(e.target.value)}
                      />
                    </form>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    );
  } else {
    return (
      <BasicLayout>
        {renderSuccessSB}
        {renderErrorSB}

        <MDBox
          mt={0}
          px={3}
          style={{
            background: '#222222',
          }}
        >
          <Grid container mt={2}>
            <MDBox
              component="img"
              src={PursuitLogo}
              justifyContent="center"
              sx={{ margin: '0 auto 20px', display: { lg: 'none' } }}
              alt="Pursuit"
              width="60%"
            />
            <Grid item xs={12} lg={12}>
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: { xs: 'block', md: 'flex' },
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          sx={{ mr: { xs: 0, sm: 0, md: 2 } }}
                        >
                          <MDInput
                            type="text"
                            label="Name"
                            name="name"
                            id="name"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            // error={errors.password && touched.password}
                            // success={passwordV.length > 0 && !errors.password}
                            inputProps={{ autoComplete: '' }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          sx={{ mt: { xs: 2, md: 0 } }}
                        >
                          <MDInput
                            type="email"
                            label="Email"
                            id="email"
                            fullWidth
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            // error={errors.password && touched.password}
                            // success={passwordV.length > 0 && !errors.password}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} mt={2} mb={2}>
                        <MDInput
                          type="textarea"
                          label="Message"
                          id="message"
                          name="message"
                          multiline
                          rows={4}
                          fullWidth
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          // error={errors.password && touched.password}
                          // success={passwordV.length > 0 && !errors.password}
                        />
                      </Grid>

                      <MDBox
                        mt={1}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <MDButton
                          type="submit"
                          variant="outlined"
                          color="info"
                          fullWidth
                        >
                          Send Message
                        </MDButton>
                      </MDBox>
                      <MDBox
                        mt={1}
                        mb={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={onChange}
                          size="invisible"
                        />
                      </MDBox>
                      <input
                        type="hidden"
                        name="fake_field"
                        value={fake_field}
                        onChange={(e) => setFakeField(e.target.value)}
                      />
                    </form>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </BasicLayout>
    );
  }
};

export default ContactPage;
