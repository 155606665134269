import { useEffect, useState } from 'react';

// react-router-dom components
import { useLocation, NavLink, useNavigate, Link } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

//  example components
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse';
import SidenavList from 'examples/Sidenav/SidenavList';
import SidenavItem from 'examples/Sidenav/SidenavItem';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

// Custom styles for the Sidenav
import SidenavRoot from 'examples/Sidenav/SidenavRoot';

// helper methods
import { logout } from 'actions/userActions';

// Assets
import PursuitLogo from 'assets/images/pursuitlogoloading-01.svg';
import LookupButtonSVG from 'assets/images/look-up-button-01.svg';
import LogoutButton from 'assets/images/logout-01.svg';

//  context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from 'context';

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split('/').slice(1)[0];
  const items = pathname.split('/').slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const navigate = useNavigate();

  let textColor = 'white';

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = 'dark';
  } else if (whiteSidenav && darkMode) {
    textColor = 'inherit';
  }

  const logoutClick = () => {
    logout(() => {
      return window.location.reload();
    });
  };

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      {window.innerWidth < 1000 ? <DashboardNavbar userName menu /> : ''}

      <MDBox pt={3} pb={1} px={2}>
        {/* <MDBox
          textAlign="center"
          display={{ xs: 'block', xl: 'none' }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer', pl: 10 }}
        >
          <MDTypography variant="h5" color="secondary">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </MDTypography>
        </MDBox> */}
        <MDBox
          component={NavLink}
          width="100%"
          to="/"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ mb: 3 }}
        >
          {brand && (
            <MDBox
              component="img"
              src={PursuitLogo}
              justifyContent="center"
              alt="Pursuit"
              width="80%"
            />
          )}
        </MDBox>

        <MDBox
          sx={{
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          <MDTypography variant="h6" color="primary" sx={{ mt: 0 }}>
            menu
          </MDTypography>
          <Link to="/dashboard/my-results">
            <MDBox
              sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <MDTypography variant="h6" color="info">
                my results
              </MDTypography>
              <img width={35} src={LookupButtonSVG} />
            </MDBox>
          </Link>

          <Link id="all-results-link" to="/dashboard/all-results">
            <MDBox
              sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <MDTypography variant="h6" color="info">
                results
              </MDTypography>
              <img width={35} src={LookupButtonSVG} />
            </MDBox>
          </Link>

          <Link id="echelon-link" to="/dashboard/pursuit-index">
            <MDBox
              sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <MDTypography variant="h6" color="info">
                pursuit index
              </MDTypography>
              <img width={35} src={LookupButtonSVG} />
            </MDBox>
          </Link>

          <Link to="/dashboard/events-calendar">
            <MDBox
              sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <MDTypography variant="h6" color="info">
                events
              </MDTypography>
              <img width={35} src={LookupButtonSVG} />
            </MDBox>
          </Link>

          <Link to="/dashboard/participation">
            <MDBox
              sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <MDTypography variant="h6" color="info">
                participation
              </MDTypography>
              <img width={35} src={LookupButtonSVG} />
            </MDBox>
          </Link>

          <MDTypography variant="h6" color="primary" sx={{ mt: 4 }}>
            preferences
          </MDTypography>

          <Link to="/dashboard/profile">
            <MDBox
              sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <MDTypography variant="h6" color="info">
                profile
              </MDTypography>
              <img width={35} src={LookupButtonSVG} />
            </MDBox>
          </Link>

          <Link to="/dashboard/account">
            <MDBox
              sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <MDTypography variant="h6" color="info">
                account
              </MDTypography>
              <img width={35} src={LookupButtonSVG} />
            </MDBox>
          </Link>

          <Link to="/dashboard/about">
            <MDBox
              sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <MDTypography variant="h6" color="info">
                about
              </MDTypography>
              <img width={35} src={LookupButtonSVG} />
            </MDBox>
          </Link>

          <Link to="/dashboard/privacy-policy">
            <MDBox
              sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <MDTypography variant="h6" color="info">
                privacy
              </MDTypography>
              <img width={35} src={LookupButtonSVG} />
            </MDBox>
          </Link>

          <MDBox
            onClick={logoutClick}
            sx={{
              mt: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <MDTypography
              variant="h6"
              color="info"
              styles={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              logout
            </MDTypography>

            <img width={35} src={LogoutButton} />
          </MDBox>

          <Link to="/dashboard/contact-us">
            <MDBox
              mt={3}
              mb={3}
              width="100%"
              display="flex"
              justifyContent="space-between"
            >
              <MDButton type="submit" variant="outlined" color="info" fullWidth>
                Contact Us
              </MDButton>
            </MDBox>
          </Link>
        </MDBox>
      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: 'Pursuit Africa',
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
