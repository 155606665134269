import React, { useState, useEffect } from 'react';

// Helpers
import { useLocation, useNavigate } from 'react-router-dom';
import Select, {
  components,
  ControlProps,
  Props,
  StylesConfig,
} from 'react-select';

// @mui material components
import Grid from '@mui/material/Grid';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

import Typography from '@mui/material/Typography';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

//  example components
import DataTable from 'examples/Tables/DataTable';

// Redux
import { useSelector } from 'react-redux';

import api from 'api';
import _ from 'lodash';
import Loader from '../../../components/Loader';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',

    background: '6fe3ed',
  }),
  option: (provided, state) => ({
    ...provided,

    background: '#222222',

    color: state.isSelected ? 'yellow' : '#6fe3ed',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#6fe3ed',
    fontSize: '12px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    color: '#6fe3ed',
    fontSize: '12px',
    padding: 0,
  }),

  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),

  control: (provided) => ({}),
  menu: (provided, state) => ({
    ...provided,
    color: '#6fe3ed',
    fontSize: '12px',
  }),
  menuList: (provided, state) => ({
    color: '#6fe3ed',
    fontSize: '12px',
  }),
  singleValue: (provided, state) => {
    return {
      ...provided,
      color: state.isSelected ? 'yellow' : '#6fe3ed',
      fontSize: '12px',
    };
  },
};

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <MDBox
        sx={{
          display: 'flex',
          borderRadius: 1.5,
          alignItems: 'center',
          pt: 0.5,
          pb: 0.5,
          pl: 1,
          pr: 1,
          fontFamily: 'MontserratRegular',
          border: '2px dotted #6fe3ed',
        }}
      >
        {/* <MDTypography fontSize="12px" variant="p" color="primary">
          Filter
        </MDTypography> */}
        <ArrowRightIcon color="secondary" fontSize="medium" />
        {children}
      </MDBox>
    </components.Control>
  );
};

const LeaderBoard = () => {
  // Get user for dashboard data
  const user = useSelector((state) => state.user);
  const token = user.data.token;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [previousEventData, setPreviousEventData] = useState([]);
  const [noFilterResults, setNoFilterResults] = useState(false);

  // Filter values
  const [selectedGenderCategory, setSelectedGenderCategory] = useState({
    label: 'All Genders',
    value: 'all',
  });
  const [selectedCategory, setSelectedCategory] = React.useState({
    label: 'All Categories',
    value: 'all',
  });

  const [categories, setCategories] = useState([]);
  const [genderCategories, setGenderCategories] = useState([]);

  const [participationResultData, setParticipationResultData] = useState({
    columns: [],
    rows: [],
  });

  const getGenderFirstChar = (gender) => {
    if (gender.charAt(0) === 'F') {
      return 'W';
    }
    return gender.charAt(0);
  };

  const fetchPreviousEvents = async () => {
    setLoading(true);
    const data = await api.get(`/events/leaderboard`, {
      params: {
        leaderBoardDiscipline: location.state.discipline,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const events = data.data.data;

    // Build Category List
    const uniqueCategories = [
      ...new Set(
        events.map((item) => item.Category__r && item.Category__r.Name)
      ),
    ];

    let categoriesStripped = uniqueCategories.filter((item) => item !== null);
    let categoriesMapped = categoriesStripped.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    categoriesMapped.unshift({
      label: 'All Categories',
      value: 'all',
    });

    if (events.length === 0) {
      setLoading(false);
      setNoResultsFound(true);
    } else {
      setCategories(categoriesMapped);

      setGenderCategories([
        {
          label: 'Al Genders',
          value: 'all',
        },
        {
          label: 'Men',
          value: 'Male',
        },
        {
          label: 'Women',
          value: 'Female',
        },
      ]);
      setPreviousEventData(events);
    }
  };

  const removeDateFromName = (str) => {
    return str.slice(5);
  };

  const generateEventsResults = () => {
    const columns = [
      {
        Header: '',
        accessor: 'Position__c',
        width: '5%',
      },
      {
        Header: '',
        accessor: 'Contact__r',
        width: '50%',
      },
      {
        Header: '',
        accessor: 'Gender__c',
        width: '15%',
      },
      {
        Header: '',
        accessor: 'Category__r',
        width: '25%',
      },
      {
        Header: '',
        accessor: 'Average_RI__c',
        width: '5%',
      },
    ];

    const rows = previousEventData.map((item) => {
      return {
        Position__c: item.Position__c,
        Contact__r: item.Contact__r.Name,
        Gender__c: item.Contact__r?.Gender__c
          ? `${getGenderFirstChar(item.Contact__r.Gender__c)} ${
              item.Position_Gender__c ? `(${item.Position_Gender__c})` : ''
            }`
          : '',
        Category__r: item.Category__r
          ? `${item.Category__r.Name} ${
              item.Position_Category__c ? `(${item.Position_Category__c})` : ''
            }`
          : '',
        Average_RI__c: Math.floor(item.Average_RI__c),
      };
    });

    setParticipationResultData({
      columns,
      rows,
    });

    setLoading(false);
  };

  useEffect(() => {
    if (previousEventData.length === 0 && !noResultsFound) {
      fetchPreviousEvents();
    }
    if (previousEventData.length > 0) {
      generateEventsResults();
    }
  }, [previousEventData]);

  const changeCategory = () => {
    const columns = [
      {
        Header: '',
        accessor: 'Position__c',
        width: '5%',
      },
      {
        Header: '',
        accessor: 'Contact__r',
        width: '50%',
      },
      {
        Header: '',
        accessor: 'Gender__c',
        width: '15%',
      },
      {
        Header: '',
        accessor: 'Category__r',
        width: '25%',
      },
      {
        Header: '',
        accessor: 'Average_RI__c',
        width: '5%',
      },
    ];

    let categoryData = [];

    if (
      (selectedCategory.value === 'all' ||
        selectedCategory.value == undefined) &&
      (selectedGenderCategory.value === 'all' ||
        selectedGenderCategory.value == undefined)
    ) {
      categoryData = previousEventData;
    }

    if (
      selectedCategory.value !== 'all' &&
      selectedGenderCategory.value !== 'all'
    ) {
      categoryData = previousEventData.filter(
        (item) =>
          item.Category__r?.Name === selectedCategory.value &&
          item.Contact__r?.Gender__c === selectedGenderCategory.value
      );
    }

    if (
      (selectedCategory.value === 'all' ||
        selectedCategory.value == undefined) &&
      selectedGenderCategory.value !== 'all'
    ) {
      categoryData = previousEventData.filter(
        (item) => item.Contact__r?.Gender__c === selectedGenderCategory.value
      );
    }

    if (
      selectedCategory.value !== 'all' &&
      (selectedGenderCategory.value === 'all' ||
        selectedGenderCategory.value == undefined)
    ) {
      categoryData = previousEventData.filter(
        (item) => item.Category__r?.Name === selectedCategory.value
      );
    }

    if (categoryData.length > 0) {
      const rows = categoryData.map((item) => {
        return {
          Event_Name_Report__c: item.Event_Name_Report__c,
          Position__c: item.Position__c,
          Contact__r: item.Contact__r.Name,
          Gender__c: item.Contact__r?.Gender__c
            ? `${getGenderFirstChar(item.Contact__r.Gender__c)} ${
                item.Position_Gender__c ? `(${item.Position_Gender__c})` : ''
              }`
            : '',
          Category__r: item.Category__r
            ? `${item.Category__r.Name} ${
                item.Position_Category__c
                  ? `(${item.Position_Category__c})`
                  : ''
              }`
            : '',
          Average_RI__c: Math.floor(item.Average_RI__c),
        };
      });
      setParticipationResultData({
        columns,
        rows,
      });
    } else {
      const rows = previousEventData.map((item) => {
        return {
          Event_Name_Report__c: '',
          Position__c: '',
          Contact__r: '',
          Gender__c: '',
          Category__r: '',
          Average_RI__c: '',
        };
      });

      setParticipationResultData({
        columns,
        rows,
      });
    }
  };

  const changeGenderCategory = () => {
    const columns = [
      {
        Header: '',
        accessor: 'Position__c',
        width: '5%',
      },
      {
        Header: '',
        accessor: 'Contact__r',
        width: '50%',
      },
      {
        Header: '',
        accessor: 'Gender__c',
        width: '15%',
      },
      {
        Header: '',
        accessor: 'Category__r',
        width: '25%',
      },
      {
        Header: '',
        accessor: 'Average_RI__c',
        width: '5%',
      },
    ];

    let categoryData = [];

    if (selectedGenderCategory.value === 'all') {
      categoryData = previousEventData;
    }

    if (selectedCategory.value === 'all') {
      categoryData = previousEventData;
    }

    if (categoryData.length > 0) {
      const rows = categoryData.map((item) => {
        return {
          Event_Name_Report__c: item.Event_Name_Report__c,
          Position__c: item.Position__c,
          Contact__r: item.Contact__r.Name,
          Gender__c: item.Contact__r?.Gender__c
            ? `${getGenderFirstChar(item.Contact__r.Gender__c)} ${
                item.Position_Gender__c ? `(${item.Position_Gender__c})` : ''
              }`
            : '',
          Category__r: item.Category__r
            ? `${item.Category__r.Name} ${
                item.Position_Category__c
                  ? `(${item.Position_Category__c})`
                  : ''
              }`
            : '',
          Average_RI__c: Math.floor(item.Average_RI__c),
        };
      });
      setNoFilterResults(false);
      setParticipationResultData({
        columns,
        rows,
      });
    } else if (
      selectedGenderCategory.value !== 'all' &&
      selectedCategory.value !== 'all'
    ) {
      setNoFilterResults(true);
    } else {
      const rows = previousEventData.map((item) => {
        return {
          Event_Name_Report__c: item.Event_Name_Report__c,
          Position__c: item.Position__c,
          Contact__r: item.Contact__r.Name,
          Gender__c: item.Contact__r?.Gender__c
            ? `${getGenderFirstChar(item.Contact__r.Gender__c)} ${
                item.Position_Gender__c ? `(${item.Position_Gender__c})` : ''
              }`
            : '',
          Category__r: item.Category__r
            ? `${item.Category__r.Name} ${
                item.Position_Category__c
                  ? `(${item.Position_Category__c})`
                  : ''
              }`
            : '',
          Average_RI__c: Math.floor(item.Average_RI__c),
        };
      });
      setNoFilterResults(false);
      setParticipationResultData({
        columns,
        rows,
      });
    }
  };

  useEffect(() => {
    if (previousEventData.length === 0) {
      fetchPreviousEvents();
    } else {
      changeCategory();
    }
  }, [selectedCategory, selectedGenderCategory]);

  // useEffect(() => {
  //   if (previousEventData.length === 0) {
  //     fetchPreviousEvents();
  //   } else {
  //     changeGenderCategory();
  //   }
  // }, [selectedGenderCategory]);

  if (loading) {
    return (
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox
              pt={50}
              px={3}
              style={{
                display: 'flex',

                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Loader />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar userName />

      <MDBox
        className="pursuitIndexButtons"
        style={{ width: '100%', paddingTop: 15 }}
      >
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <button
            // className={activeButton == index ? 'active' : undefined}
            key={`title-${location.state.discipline}`}
            onClick={() => alert('clieked')}
          >
            {location.state.discipline}
          </button>
        </MDBox>
      </MDBox>

      <MDBox>
        {participationResultData.rows.length > 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 1.5rem',
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    width: '100%',
                    mt: 1,
                    fontSize: '1.5rem',
                    fontWeight: 100,
                    fontFamily: 'MontserratItal',
                  }}
                  autoFocus
                  variant="h6"
                  color="#ffffff"
                >
                  {/* {removeDateFromName(
                    participationResultData.rows[0].
                  )} */}
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'center',
                    width: '100%',
                    textTransform: 'uppercase',
                    fontSize: '0.8rem',
                    fontweight: 300,
                    fontFamily: 'MontserratBold',
                  }}
                  variant="h6"
                  color="secondary"
                >
                  {location.state.distanceName}
                </Typography>
              </MDBox>

              <MDBox py={1} px={2} id="leader-board-results">
                <MDBox
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <MDBox
                    display="flex"
                    alignItems="center"
                    sx={{ width: '100%', mr: 1 }}
                  >
                    <Select
                      key={1}
                      styles={customStyles}
                      options={categories}
                      isSearchable={false}
                      value={selectedCategory[0]}
                      placeholder={'Categories'}
                      components={{ Control }}
                      onChange={setSelectedCategory}
                    />
                  </MDBox>

                  <MDBox
                    display="flex"
                    alignItems="center"
                    sx={{ width: '70%' }}
                  >
                    <Select
                      key={2}
                      styles={customStyles}
                      options={genderCategories}
                      isSearchable={false}
                      value={selectedGenderCategory[0]}
                      placeholder={'Genders'}
                      components={{ Control }}
                      onChange={setSelectedGenderCategory}
                    />
                  </MDBox>
                  {/* <MDBox
                    display="flex"
                    alignItems="center"
                    sx={{ width: '70%' }}
                  >
                    <Select
                      key={3}
                      styles={customStyles}
                      options={batchCategories}
                      isSearchable={false}
                      value={selectedBatch[0]}
                      placeholder={'Batch'}
                      components={{ Control }}
                      onChange={setSelectedBatch}
                    />
                  </MDBox> */}
                </MDBox>

                <DataTable
                  table={participationResultData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                  canSearch
                />
                {noFilterResults && (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <MDBox
                        pt={2}
                        px={3}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          id="leader-board-results"
                        >
                          No Results Found
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                )}
              </MDBox>
            </Grid>
          </Grid>
        )}

        {noResultsFound && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox
                pt={2}
                px={3}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <MDTypography
                  variant="h5"
                  fontWeight="medium"
                  id="leader-board-results"
                >
                  No Results Found
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default LeaderBoard;
