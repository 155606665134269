import { useState, useEffect } from 'react';

// react-router-dom components
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Helper Libraries
import api from 'api';
import _ from 'lodash';

// @mui material components
import Grid from '@mui/material/Grid';
import { IconButton } from '@mui/material';

// Pursuit components
import MDBox from 'components/MDBox';
import Loader from '../../../components/Loader';
import MDTypography from '../../../components/MDTypography';

// Data components
import DataTable from 'examples/Tables/DataTable';
import DefaultCell from 'layouts/dashboards/components/DefaultCell';
import DisciplineCell from 'layouts/dashboards/components/DisciplineCell';

// Assets
import LookupButtonSVG from 'assets/images/look-up-button-01.svg';

const UpcomingEventsList = ({ filterEventId }) => {
  // Get user for dashboard data
  const user = useSelector((state) => state.user);
  const token = user.data.token;

  // Navigate to Riders Details Page
  const navigate = useNavigate();

  // Load on fetching data
  const [loading, setLoading] = useState(false);

  // Set data from API to consume in components
  const [eventData, setEventData] = useState([]);

  // Manage state for DataTable
  const [eventsResultData, setEventsResultData] = useState({
    columns: [],
    rows: [],
  });

  const removeDateFromName = (str) => {
    return str.slice(5);
  };

  const fetchUpcomingEvents = async () => {
    setLoading(true);

    const res = await api.get('/events/upcoming-faces-events', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    let upcomingEventsAndDistances = res.data.data;
    // TODO: We order by Start Date instead of Discipline
    // let upcomingEventsAndDistancesOrderedByRoadFirst = _.orderBy(
    //   upcomingEventsAndDistances,
    //   ['Event__r.Name'],
    //   ['desc']
    // );

    // let upcomingEventsListFilterOrSorted = upcomingEventsAndDistances;

    // // When rendered from another component to show only a single event
    // if (filterEventId) {
    //   upcomingEventsListFilterOrSorted = upcomingEventsAndDistances.filter((item) => item.id);
    // } else {
    // }

    let upcomingEventsAndDistancesOrderedByRoadFirst = _.orderBy(
      upcomingEventsAndDistances,
      ['Date__c'],
      ['asc']
    );

    let upcomingEventsAndDistancesNo10km =
      upcomingEventsAndDistancesOrderedByRoadFirst.filter(
        (item) => item.Distance_km__c > 10
      );

    setLoading(false);
    setEventData(upcomingEventsAndDistancesNo10km);
  };

  useEffect(() => {
    if (eventData.length === 0) {
      fetchUpcomingEvents();
    }
  }, []);

  const generateEventsResults = () => {
    const columns = [
      {
        Header: `${filterEventId ? "WHO'S PARTICIPATING" : 'EVENTS'}`,
        accessor: 'Name',
        width: '65%',
      },
      { Header: 'KM', accessor: 'Distance_km__c', width: '15%' },
      { Header: 'DATE', accessor: 'Date__c', width: '20%' },
      {
        Header: 'SEARCH',
        accessor: 'Details',
        width: '5%',
        align: 'right',
      },
    ];

    const rows = eventData.map((item) => {
      return {
        Name: item.Event__r.Name ? (
          <DefaultCell color="info">
            {removeDateFromName(item.Event__r.Name)}
          </DefaultCell>
        ) : (
          ''
        ),
        Distance_km__c: item.Distance_km__c ? (
          <DefaultCell color="info">{item.Distance_km__c}</DefaultCell>
        ) : (
          ''
        ),
        Date__c: item.Date__c ? <DefaultCell>{item.Date__c}</DefaultCell> : '',
        Details: (
          <IconButton
            className="base-button"
            edge="start"
            color="inherit"
            onClick={() => {
              return navigate('/dashboard/whos-participating', {
                state: {
                  distanceId: item.Id,
                  distanceName: item.Name,
                },
              });
            }}
            aria-label="open"
            sx={{
              padding: 0,
              marginTop: -0.5,
              marginRight: 1,
              marginLeft: 0,
            }}
          >
            <img width={30} src={LookupButtonSVG} />
          </IconButton>
        ),
      };
    });

    setEventsResultData({
      columns,
      rows,
    });

    setLoading(false);
  };

  // let currentDate = new Date();
  // let currentYear = currentDate.getFullYear();

  useEffect(() => {
    if (eventData.length > 0) {
      generateEventsResults();
    }
  }, [eventData]);

  if (loading) {
    return (
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox
              pt={50}
              px={3}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Loader />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  return (
    <MDBox>
      {eventsResultData.rows.length > 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox py={1} px={2}>
              <DataTable
                table={eventsResultData}
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={false}
                noEndBorder
                enableDivider
              />
            </MDBox>
          </Grid>
        </Grid>
      )}
    </MDBox>
  );
};

export default UpcomingEventsList;
