import { useState, useEffect } from 'react';

// react-router components
import { useNavigate, Link } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import { Tabs, Tab } from '@mui/material';

import Box from '@mui/material/Box';

import { useSelector } from 'react-redux';

//  base styles
import breakpoints from 'assets/theme/base/breakpoints';

//  contexts
import { useMaterialUIController } from 'context';

function LinkTab(props) {
  const navigate = useNavigate();
  return (
    <Tab
      sx={{ backgroundColor: '#333333', borderRadius: 0, marginBottom: 0.5 }}
      component="a"
      onClick={(event) => {
        event.preventDefault();
        navigate(props.href);
      }}
      {...props}
    />
  );
}

function NavTabs({ page }) {
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');
  const [tabValue, setTabValue] = useState(page);

  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, isMini, fixedNavbar, darkMode } = controller;
  const user = useSelector((state) => state.user);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        display: { xs: 'block', md: 'none' },
        width: '100%',
        backgroundColor: '#333333',
      }}
    >
      <Tabs
        centered
        sx={{ borderRadius: 0, p: 0 }}
        value={tabValue}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs"
      >
        <LinkTab value="preferences" label="Account" href="/preferences" />
        <LinkTab logo value="profile" label="Profile" href="/profile" />
      </Tabs>
    </Box>
  );
}

// Setting default props for the NavTabs
NavTabs.defaultProps = {
  children: '',
};

// Typechecking props for the NavTabs
NavTabs.propTypes = {
  children: PropTypes.node,
};

export default NavTabs;
