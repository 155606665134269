import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';

//  context
import { useMaterialUIController, setMiniSidenav } from 'context';

// Assets
import myResultsOn from 'assets/images/pursuit-menu-myresults-on.svg';
import allResultsOn from 'assets/images/pursuit-menu-allresults-on.svg';
import profileOn from 'assets/images/pursuit-menu-profile-on.svg';
import calendarOn from 'assets/images/pursuit-menu-calendar-on.svg';
import echelonOn from 'assets/images/pursuit-menu-echelon-on.svg';
import myResultsOff from 'assets/images/pursuit-menu-myresults-off.svg';
import allResultsOff from 'assets/images/pursuit-menu-allresults-off.svg';
import profileOff from 'assets/images/pursuit-menu-profile-off.svg';
import calendarOff from 'assets/images/pursuit-menu-calendar-off.svg';
import echelonOff from 'assets/images/pursuit-menu-echelon-off.svg';

import { Link, useLocation } from 'react-router-dom';

const StickyNav = () => {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);
  const location = useLocation();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const links = [
    {
      to: '/menu',
      name: 'menu',
      iconOff: profileOff,
      iconOn: profileOn,
    },
    {
      to: '/dashboard/events-calendar',
      name: 'events',
      iconOff: calendarOff,
      iconOn: calendarOn,
    },
    {
      to: '/dashboard/pursuit-index',
      name: 'index',
      iconOff: echelonOff,
      iconOn: echelonOn,
    },
    {
      to: '/dashboard/all-results',
      name: 'results',
      iconOff: allResultsOff,
      iconOn: allResultsOn,
    },
    {
      to: '/dashboard/my-results',
      name: 'my results',
      iconOff: myResultsOff,
      iconOn: myResultsOn,
    },
  ];

  return (
    <Box sx={{ pb: 8 }} ref={ref}>
      <CssBaseline />

      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          boxShadow: '0 0 50px 15px rgba(1, 1, 1, 0.5)',
        }}
        square
        elevation={3}
      >
        <BottomNavigation
          sx={{
            paddingTop: 0.5,
            paddingBottom: 3,
            height: '100%',
            backgroundColor: '#222222',
          }}
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          {links.map((link, index) => {
            let active = location.pathname === link.to;

            return index === 0 ? (
              <BottomNavigationAction
                disableRipple
                key={index}
                style={{
                  color: active ? '#ffe139' : '#70FAFD',
                  textAlign: 'center',
                }}
                label={link.name}
                onClick={handleMiniSidenav}
                icon={
                  <img
                    width={64}
                    style={{ marginBottom: -5 }}
                    src={active ? link.iconOn : link.iconOff}
                  />
                }
              />
            ) : (
              <BottomNavigationAction
                component={Link}
                disableRipple
                key={index}
                to={link.to}
                style={{
                  color: active ? '#ffe139' : '#70FAFD',
                  textAlign: 'center',
                }}
                label={link.name}
                icon={
                  <img
                    width={64}
                    style={{ marginBottom: -5 }}
                    src={active ? link.iconOn : link.iconOff}
                  />
                }
              />
            );
          })}
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default StickyNav;
