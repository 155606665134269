// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Base Styles
import typography from 'assets/theme/base/typography';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Icons
import { ReactComponent as MountainBikingIcon } from 'assets/images/mountainbike-01.svg';
import { ReactComponent as RoadCyclingIcon } from 'assets/images/roadcycling-01.svg';
import { ReactComponent as TrailRunningIcon } from 'assets/images/trailrunning-01.svg';
import { ReactComponent as RoadRunningIcon } from 'assets/images/roadrunning-01.svg';
import { ReactComponent as SwimRunIcon } from 'assets/images/swimrun-01.svg';
import { ReactComponent as ObstacleCourseIcon } from 'assets/images/obstaclecourse-01.svg';
import { ReactComponent as SwimIcon } from 'assets/images/swim-01.svg';
import LookupButtonSVG from 'assets/images/look-up-button-01.svg';

function DisciplineCell({ value, icon, eventLink, image, color = 'text' }) {
  const { size } = typography;
  const generateIcon = (discipline) => {
    switch (discipline) {
      case 'Road Running':
        return (
          <RoadRunningIcon
            width={30}
            fill={color === 'primary' ? '#ffe139' : '#70FAFD'}
          />
        );
      case 'Road Cycling':
        return (
          <RoadCyclingIcon
            width={30}
            fill={color === 'primary' ? '#ffe139' : '#70FAFD'}
          />
        );
      case 'Trail Running':
        return (
          <TrailRunningIcon
            width={30}
            fill={color === 'primary' ? '#ffe139' : '#70FAFD'}
          />
        );
      case 'Mountain Biking':
        return (
          <MountainBikingIcon
            width={30}
            fill={color === 'primary' ? '#ffe139' : '#70FAFD'}
          />
        );
      case 'Gravel Biking':
        return (
          <MountainBikingIcon
            width={30}
            fill={color === 'primary' ? '#ffe139' : '#70FAFD'}
          />
        );

      case 'Swimrun':
        return (
          <SwimRunIcon
            width={30}
            fill={color === 'primary' ? '#ffe139' : '#70FAFD'}
          />
        );
      case 'Swim':
        return (
          <SwimIcon
            width={30}
            fill={color === 'primary' ? '#ffe139' : '#70FAFD'}
          />
        );
      default:
        return (
          <ObstacleCourseIcon
            width={30}
            fill={color === 'primary' ? '#ffe139' : '#70FAFD'}
          />
        );
    }
  };

  if (eventLink) {
    return (
      <a href={eventLink} target="_blank">
        <MDBox display="flex" justifyContent="end" alignItems="center" px={0}>
          <img width={30} style={{ marginRight: 5 }} src={LookupButtonSVG} />
          <MDTypography variant="button" fontWeight="light" color={color}>
            {value}
          </MDTypography>
          <MDBox lineHeight={0}>
            {/* <ReactLogo width={30} fill="#fff" /> */}
            {generateIcon(image)}
            {/* <img width={50} src={image} /> */}
            {/* <Icon sx={{ fontWeight: 'bold' }} fontSize="small">
              {icon.name}
            </Icon> */}
          </MDBox>
        </MDBox>
      </a>
    );
  }

  return (
    <MDBox display="flex" justifyContent="end" alignItems="end">
      <MDTypography
        sx={{
          fontSize: { xs: size.xs, sm: size.sm, md: size.md, lg: size.lg },
        }}
        variant="button"
        fontWeight="light"
        color="text"
      >
        {value}
      </MDTypography>
      <MDBox lineHeight={0}>
        {generateIcon(image)}
        {/* <Icon sx={{ fontWeight: 'bold' }} fontSize="small">
          {icon.name}
        </Icon> */}
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the DisciplineCell
DisciplineCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  // icon: PropTypes.shape({
  //   color: PropTypes.oneOf(['info', 'success', 'warning', 'error']).isRequired,
  //   name: PropTypes.string.isRequired,
  // }).isRequired,
};

export default DisciplineCell;
