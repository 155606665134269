import React from 'react';
// @mui material components
import Grid from '@mui/material/Grid';

import MDBox from 'components/MDBox';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import TimelineList from 'examples/Timeline/TimelineList';
import TimelineItem from 'examples/Timeline/TimelineItem';

// Data
import timelineData from './data/timelineData';

const IOSInstructions = () => {
  const renderTimelineItems = timelineData.map(
    ({ color, icon, title, dateTime, description, badges, lastItem }) => (
      <TimelineItem
        key={title + color}
        color={color}
        icon={icon}
        title={title}
        dateTime={dateTime}
        description={description}
        badges={badges}
        lastItem={lastItem}
      />
    )
  );

  return (
    <MDBox my={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <TimelineList title="Using the Safari browser, install the Pursuit app on iPhone">
            {renderTimelineItems}
          </TimelineList>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default IOSInstructions;
