import {
  LOGIN_USER,
  LOGIN_USER_ERROR,
  RECEIVED_LOGGED_IN_USER,
  UPDATE_USER,
  RECEIVED_UPDATED_USER,
  UPDATE_USER_ERROR,
} from "../actions/actionTypes";

const initialState = {
  data: {},
  isFetching: false,
  isError: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        data: {},
      });
    case LOGIN_USER_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        isError: true,
        data: action.data,
      });
    case RECEIVED_LOGGED_IN_USER:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        data: action.data,
      });
    // case UPDATE_USER:
    //   return Object.assign({}, state, {
    //     isFetching: true,
    //     isError: false,
    //     data: {},
    //   });
    // case UPDATE_USER_ERROR:
    //   return Object.assign({}, state, {
    //     isFetching: false,
    //     isError: true,
    //     data: action.data,
    //   });
    case RECEIVED_UPDATED_USER:
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        data: action.data,
      });
    default:
      return state;
  }
};

export default userReducer;
