// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Pursuit components
import MDBox from "components/MDBox";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function ChangePasswordForm({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { password, newPassword, repeatPassword } = formField;
  const {
    newPassword: newPasswordV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
  } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <FormField
          type={password.type}
          label={password.label}
          name={password.name}
          value={passwordV}
          placeholder={password.placeholder}
          error={errors.password && touched.password}
          success={passwordV.length > 0 && !errors.password}
          inputProps={{ autoComplete: "" }}
        />
        <FormField
          type={newPassword.type}
          label={newPassword.label}
          name={newPassword.name}
          value={newPasswordV}
          placeholder={newPassword.placeholder}
          error={errors.newPassword && touched.newPassword}
          success={newPasswordV.length > 0 && !errors.newPassword}
          inputProps={{ autoComplete: "" }}
        />
        <FormField
          type={repeatPassword.type}
          label={repeatPassword.label}
          name={repeatPassword.name}
          value={repeatPasswordV}
          placeholder={repeatPassword.placeholder}
          error={errors.repeatPassword && touched.repeatPassword}
          success={repeatPasswordV.length > 0 && !errors.repeatPassword}
          inputProps={{ autoComplete: "" }}
        />
      </MDBox>
    </MDBox>
  );
}

// typechecking props for ChangePasswordForm
ChangePasswordForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChangePasswordForm;
