import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";

// Helper methods
import jwt from "jsonwebtoken";
import { registerUser } from "../../../auth";

// Pursuit components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Assets
import LogoWithText from "assets/images/LogoWithText.svg";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Loader from "components/Loader";

const ActivateAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [userName, setUserName] = useState("");

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  useEffect(() => {
    const token = params.id;

    if (token) {
      const { firstName } = jwt.decode(token);
      setUserName(firstName);
    }
  });

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={errorMsg}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const handleSubmit = async () => {
    setLoading(true);
    const result = await registerUser(params.id);

    if (!result.data.success) {
      setLoading(false);
      setErrorMsg(result.data.error);
      openErrorSB();
    }

    if (result.data.success) {
      setLoading(false);
      navigate("/sign-in", {
        state: {
          message: "activated",
          userName: userName,
        },
      });
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <BasicLayout>
      {renderErrorSB}

      <MDBox mx={2} p={2} mb={1} textAlign="center">
        <MDBox mx={2} p={2} mb={1} textAlign="center">
          <img src={LogoWithText} />
        </MDBox>
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Hello {userName}, please activate your account.
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox
          mt={2}
          width="100%"
          display="flex"
          justifyContent="space-between"
        >
          <MDButton
            onClick={handleSubmit}
            variant="outlined"
            color="info"
            fullWidth
          >
            Activate Account
          </MDButton>
        </MDBox>
      </MDBox>
    </BasicLayout>
  );
};

export default ActivateAccount;
