import { useState, useEffect } from 'react';

// Helpers
import { useNavigate } from 'react-router-dom';

// @mui material components
import Grid from '@mui/material/Grid';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import IconButton from '@mui/material/IconButton';

//  example components
import DataTable from 'examples/Tables/DataTable';

// Redux
import { useSelector } from 'react-redux';

import api from 'api';
import _ from 'lodash';
import Loader from '../../../components/Loader';

// Assets
import LookupButtonSVG from 'assets/images/look-up-button-01.svg';

const AllResults = () => {
  // Get user for dashboard data
  const user = useSelector((state) => state.user);
  const token = user.data.token;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [previousEventData, setPreviousEventData] = useState([]);

  const [participationResultData, setParticipationResultData] = useState({
    columns: [],
    rows: [],
  });

  const fetchPreviousEvents = async () => {
    setLoading(true);
    const data = await api.get(`/events/results?type=events`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const events = data.data.data;

    if (events.length === 0) {
      setLoading(false);
      setNoResultsFound(true);
    } else {
      setPreviousEventData(events);
    }
  };

  useEffect(() => {
    if (previousEventData.length === 0 && !noResultsFound) {
      fetchPreviousEvents();
    }
  }, []);

  const removeDateFromName = (str) => {
    return str.slice(5);
  };

  const generateEventsResults = () => {
    const columns = [
      { Header: '', accessor: 'Name', width: '65%' },
      { Header: '', accessor: 'Start_Date__c', width: '35%' },
      { Header: '', accessor: 'Details' },
    ];

    const rows = previousEventData.map((item, index) => {
      return {
        Name: removeDateFromName(item.Name),
        Start_Date__c: item.Start_Date__c,
        Details: (
          <IconButton
            edge="start"
            id={`all-result-icon-${index}`}
            color="inherit"
            onClick={() => {
              return navigate('/dashboard/distance-results', {
                state: {
                  eventName: item.Name,
                },
              });
            }}
            aria-label="open"
            sx={{
              padding: 0,
              marginBottom: 0.5,
              marginRight: 1,
              marginLeft: 0,
              marginTop: '-2px',
            }}
          >
            <img width={30} src={LookupButtonSVG} />
          </IconButton>
        ),
      };
    });

    setParticipationResultData({
      columns,
      rows,
    });

    setLoading(false);
  };

  useEffect(() => {
    if (previousEventData.length > 0) {
      generateEventsResults();
    }
  }, [previousEventData]);

  if (loading) {
    return (
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox
              pt={50}
              px={3}
              style={{
                display: 'flex',

                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Loader />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  return (
    <>
      <MDBox id="all-results-table">
        {participationResultData.rows.length > 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox py={1} px={2}>
                <DataTable
                  table={participationResultData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  canSearch={true}
                  noEndBorder
                />
              </MDBox>
            </Grid>
          </Grid>
        )}
        {noResultsFound && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox
                pt={2}
                px={3}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <MDTypography variant="h5" fontWeight="medium">
                  No Results Found
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        )}
      </MDBox>
    </>
  );
};

export default AllResults;
