// Helper Methods
import api from "../api";
import { persistor } from "../store";

export const preRegisterUser = async (user) => {
  try {
    const res = await api.post("/auth/pre-register", JSON.stringify(user));
    return res;
  } catch (error) {
    return error.response;
  }
};

export const registerUser = async (token) => {
  try {
    const res = await api.post("/auth/register", { token });
    return res;
  } catch (error) {
    return error.response;
  }
};

export const isAuthenticated = () => {
  if (typeof window === "undefined") {
    return false;
  }

  if (localStorage.getItem("jwt")) {
    return JSON.parse(localStorage.getItem("jwt"));
  }

  return false;
};

export const logout = async () => {
  await persistor.purge();
  return api
    .get("/auth/logout")
    .then(() => {
      return window.location.reload();
    })
    .catch((error) => {
      return error;
    });
};
