import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { firstName, lastName, idNumber, passportNumber, email },
} = checkout;

export default Yup.object().shape({
  [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
});
