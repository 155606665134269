export default {
  formId: 'user-registration-form',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'First Name',
      type: 'tex',
      errorMsg: 'First name is required.',
    },
    lastName: {
      name: 'lastName',
      label: 'Last Name',
      type: 'tex',
      errorMsg: 'last name is required.',
    },
    email: {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      errorMsg: 'Email address is required.',
      invalidMsg: 'Your email address is invalid',
    },
    repeatEmail: {
      name: 'repeatEmail',
      label: 'Confirm Email Address',
      type: 'email',
      errorMsg: 'Email address is required.',
      invalidMsg: 'Your email address does not match',
    },
    password: {
      name: 'password',
      label: 'Password',
      type: 'password',
      errorMsg: 'Password is required.',
      invalidMsg: 'Your password should be more than 6 characters.',
    },
    repeatPassword: {
      name: 'repeatPassword',
      label: 'Repeat Password',
      type: 'password',
      errorMsg: 'Password is required.',
      invalidMsg: "Your password doesn't match.",
    },
    idNumber: {
      name: 'idNumber',
      label: 'RSA ID Number',
      type: 'text',
      errorMsg: 'ID Number is required.',
      invalidMsg: 'Your ID number must be 13 digitals.',
    },
    passportNumber: {
      name: 'passportNumber',
      label: 'Passport Number',
      type: 'text',
      errorMsg: 'Passport Number is required.',
    },
  },
};
