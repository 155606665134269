import checkout from "./form";

const {
  formField: { email, password },
} = checkout;

export default {
  [email.name]: "",
  [password.name]: "",
};
