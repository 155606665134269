import { useState, useEffect } from 'react';

// react-router-dom components
import { Link, useNavigate, useLocation } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Helper methods
import { thunkLoginUser } from '../../../actions/userActions';

// formik components
import { Formik, Form } from 'formik';
import { PersistFormikValues } from 'formik-persist-values';

// @mui material components
import Loader from 'components/Loader';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

import LogoWithText from 'assets/images/LogoWithText.svg';

// Returning layout schemas for form and form fields
import validations from './schemas/validations';
import form from './schemas/form';
import initialValues from './schemas/initialValues';
import SignInForm from './SignInForm';

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [welcomeSB, setWelcomeSB] = useState(false);
  const [resetSB, setResetSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openWelcomeSB = () => setWelcomeSB(true);
  const closeWelcomeSB = () => setWelcomeSB(false);

  const openResetSB = () => setResetSB(true);
  const closeResetSB = () => setResetSB(false);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={errorMsg}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Welcome"
      content="We have sent you a confirmation email. Please check your junk mailbox if you do not see any email from Pursuit."
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const renderWelcomeSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Activated"
      content={`Hello ${location?.state?.userName}, your account is now active. Please login below.`}
      open={welcomeSB}
      onClose={closeWelcomeSB}
      close={closeWelcomeSB}
    />
  );

  const renderResetSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Password Updated"
      content={`Please login using your new password.`}
      open={resetSB}
      onClose={closeResetSB}
      close={closeResetSB}
    />
  );

  useEffect(() => {}, [user]);

  useEffect(() => {
    if (location?.state?.message === 'welcome') {
      openSuccessSB();
    }

    if (location?.state?.message === 'activated') {
      openWelcomeSB();
    }

    if (location?.state?.message === 'password-reset') {
      openResetSB();
    }
  }, []);

  const { formId, formField } = form;

  const submitForm = async (values, actions) => {
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const handleSubmit = async (values, actions) => {
    setLoading(true);

    dispatch(
      thunkLoginUser({
        email: values.email.toLowerCase(),
        password: values.password,
      })
    ).then((res) => {
      if (!res.data.success) {
        setLoading(false);
        setErrorMsg(res.data.data.error);
        openErrorSB();
      }
      if (res.data.success) {
        setLoading(false);
        submitForm(values, actions);
        navigate('/dashboard/my-results');
      }
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <BasicLayout>
      {renderSuccessSB}
      {renderErrorSB}
      {renderWelcomeSB}
      {renderResetSB}

      <MDBox mx={2} p={2} mb={1} textAlign="center">
        <MDBox mx={2} p={2} mb={1} textAlign="center">
          <img src={LogoWithText} />
        </MDBox>

        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Login
        </MDTypography>
      </MDBox>
      <MDBox pb={3} px={1}>
        <Formik
          initialValues={initialValues}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form id={formId} autoComplete="off">
              <MDBox>
                <SignInForm
                  formData={{
                    values,
                    touched,
                    formField,
                    errors,
                  }}
                />
                ;
                <MDBox
                  mt={2}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDButton
                    disabled={isSubmitting}
                    type="submit"
                    variant="outlined"
                    color="info"
                    fullWidth
                  >
                    Login
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Don&apos;t have an account?&nbsp;&nbsp;
                  <MDTypography
                    component={Link}
                    to="/sign-up"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                  >
                    Register
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Want to get in touch?&nbsp;&nbsp;
                  <MDTypography
                    component={Link}
                    to="/contact-us"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                  >
                    Contact Us
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <PersistFormikValues
                name="user-login-form"
                ignoreValues={['password']}
              />
            </Form>
          )}
        </Formik>
      </MDBox>
    </BasicLayout>
  );
};

export default SignIn;
