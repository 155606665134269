// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Pursuit components
import MDBox from "components/MDBox";

// Returning User page components
import FormField from "layouts/pages/users/new-user/components/FormField";

const ResetPasswordForm = ({ formData }) => {
  const { formField, values, errors, touched } = formData;
  const { password, repeatPassword } = formField;
  const { password: passwordV, repeatPassword: repeatPasswordV } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <MDBox mb={2}>
          <FormField
            type={password.type}
            label={password.label}
            name={password.name}
            value={passwordV}
            placeholder={password.placeholder}
            error={errors.password && touched.password}
            success={passwordV.length > 0 && !errors.password}
            inputProps={{ autoComplete: "" }}
          />

          <FormField
            type={repeatPassword.type}
            label={repeatPassword.label}
            name={repeatPassword.name}
            value={repeatPasswordV}
            placeholder={repeatPassword.placeholder}
            error={errors.repeatPassword && touched.repeatPassword}
            success={repeatPasswordV.length > 0 && !errors.repeatPassword}
            inputProps={{ autoComplete: "" }}
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

// typechecking props for ResetPasswordForm
ResetPasswordForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ResetPasswordForm;
