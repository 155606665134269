// @mui material components
import Container from '@mui/material/Container';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

function Content() {
  return (
    <>
      <Container>
        <MDBox mx={1} p={1} mb={1} mt={2} textAlign="left">
          <MDBox>
            <MDTypography variant="body2">
              Welcome to Pursuit, the latest app for tracking and measuring your
              performance in outdoor mass-participation sports events in South
              Africa. This app is designed to help you pursue your goals and
              compete with other athletes in your sports disciplines.
            </MDTypography>

            <MDTypography variant="body2" mt={2}>
              The four main menu items are My Results, Results, Pursuit Index,
              and Events.
            </MDTypography>

            <MDTypography variant="body2" mt={2}>
              The My Results menu showcases your results from events you've
              participated in, as well as your overall performance history.
            </MDTypography>

            <MDTypography variant="body2" mt={2}>
              The Results menu allows you to browse and search for results from
              a selected range of outdoor sports events across South Africa.
            </MDTypography>

            <MDTypography variant="body2" mt={2}>
              The Pursuit Index menu showcases the latest Pursuit Leaderboards,
              where you can see how you rank against other athletes in your
              sport. The peer-to-peer performance index is based only on actual,
              verified event results data*. It is calculated using two
              parameters: the Total Index and the Event Index. The Total Index
              is the average of the top three event indexes per discipline,
              while the Event Index is calculated based on your final position
              in the event, the strength of the athletes in the event, the event
              rating, and the event index over three years.
            </MDTypography>

            <MDTypography variant="body2" mt={2}>
              Finally, under the Events menu, you can browse, diarise and enter
              upcoming events in your chosen sports disciplines.
            </MDTypography>

            <MDTypography variant="body2" mt={2}>
              Join the thriving Pursuit community today to start tracking your
              performance like a pro!
            </MDTypography>

            <MDTypography variant="body2" mt={2}>
              *Event results data is collected from a number of South African
              Events going back to the year 2000. The Pursuit results data is
              continually being updated.
            </MDTypography>
          </MDBox>
        </MDBox>
      </Container>
    </>
  );
}

export default Content;
