import React, { useEffect, useState } from 'react';

// prop-type is a library for typechecking of props
import PropTypes from 'prop-types';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// Pursuit components
import MDBox from 'components/MDBox';
import { FormControl, FormControlLabel, Radio } from '@mui/material';

// Returning User page components
import FormField from 'layouts/pages/users/new-user/components/FormField';

const ProfileForm = ({
  formData,
  disabled,
  onPhoneValue,
  phoneInitialValue,
}) => {
  const { formField, values, errors, touched } = formData;
  const { firstName, lastName, idNumber, passportNumber, email } = formField;

  const {
    firstName: firstNameV,
    lastName: lastNameV,
    idNumber: idNumberV,
    passportNumber: passportNumberV,
    email: emailV,
  } = values;

  const [rsaIDField, setRsaIdField] = useState(values.idNumber);
  const [passportField, setPassportField] = useState(values.passportNumber);
  const [phoneValue, setPhoneValue] = useState(phoneInitialValue);

  const handleToggleID = (id) => {
    if (id === 'rsa') {
      setRsaIdField(true);
      setPassportField(false);
    }

    if (id === 'passport') {
      setRsaIdField(false);
      setPassportField(true);
    }
  };

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <MDBox mb={2}>
          <FormField
            type={firstName.type}
            label={firstName.label}
            name={firstName.name}
            value={firstNameV}
            placeholder={firstName.placeholder}
            error={!disabled ? errors.firstName && touched.firstName : ''}
            success={
              !disabled ? firstNameV.length > 0 && !errors.firstName : ''
            }
          />
        </MDBox>
        <MDBox mb={2}>
          <FormField
            type={lastName.type}
            label={lastName.label}
            name={lastName.name}
            value={lastNameV}
            placeholder={lastName.placeholder}
            error={!disabled ? errors.lastName && touched.lastName : ''}
            success={!disabled ? lastNameV.length > 0 && !errors.lastName : ''}
          />
        </MDBox>

        <FormControl size="small">
          <FormControlLabel
            control={
              <Radio
                label="RSA ID Number"
                name="rsa-id-number"
                checked={rsaIDField}
                onClick={() => handleToggleID('rsa')}
              />
            }
            label="RSA ID Number"
          />
        </FormControl>
        <FormControl size="small">
          <FormControlLabel
            control={
              <Radio
                label="Passport Number"
                name="passport-number"
                checked={passportField}
                onClick={() => handleToggleID('passport')}
              />
            }
            label="Passport Number"
          />
        </FormControl>

        {passportField && (
          <MDBox mb={2}>
            <FormField
              type={passportNumber.type}
              label={passportNumber.label}
              name={passportNumber.name}
              value={passportNumberV}
              placeholder={passportNumber.placeholder}
              error={
                !disabled ? errors.passportNumber && touched.passportNumber : ''
              }
              success={
                !disabled
                  ? passportNumberV.length > 0 && !errors.passportNumber
                  : ''
              }
            />
          </MDBox>
        )}

        {rsaIDField && (
          <MDBox mb={2}>
            <FormField
              type={idNumber.type}
              label={idNumber.label}
              name={idNumber.name}
              value={idNumberV}
              placeholder={idNumber.placeholder}
              error={!disabled ? errors.idNumber && touched.idNumber : ''}
              success={
                !disabled ? idNumberV.length > 0 && !errors.idNumber : ''
              }
            />
          </MDBox>
        )}

        <MDBox mb={2}>
          <FormField
            type={email.type}
            label={email.label}
            name={email.name}
            value={emailV}
            placeholder={email.placeholder}
            error={!disabled ? errors.email && touched.email : ''}
            success={!disabled ? emailV.length > 0 && !errors.email : ''}
          />
        </MDBox>

        <MDBox mb={2}>
          <PhoneInput
            country={'za'}
            value={phoneInitialValue}
            inputStyle={{
              color: 'green',
            }}
            onChange={(phone) => {
              onPhoneValue(phone);
              setPhoneValue(phone);
            }}
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

// typechecking props for ProfileForm
ProfileForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ProfileForm;
