import React from 'react';

// Assets
import PursuitLogo from 'assets/images/pursuit_icon_outline.svg';
import LoadingText from 'assets/images/pursuitlogoloading-01.svg';

const Loader = () => {
  return (
    <div>
      <div
        className="ring"
        style={{
          backgroundImage: `url(${PursuitLogo})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      >
        <span></span>
      </div>
      <img
        src={LoadingText}
        width={100}
        className="loadingText"
        alt="pursuit loading text"
      />
    </div>
  );
};

export default Loader;
