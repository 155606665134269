import { useState, useEffect } from 'react';

// react-router components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Container from '@mui/material/Container';
import Icon from '@mui/material/Icon';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import PursuitLogo from 'assets/images/pursuit_icon_outline.svg';

//  example components
import DefaultNavbarLink from 'examples/Navbars/DefaultNavbar/DefaultNavbarLink';
import DefaultNavbarMobile from 'examples/Navbars/DefaultNavbar/DefaultNavbarMobile';

//  base styles
import breakpoints from 'assets/theme/base/breakpoints';

// DefaultNavbar dropdown menus
import AuthenticationMenu from 'examples/Navbars/DefaultNavbar/Menus/AuthenticationMenu';

//  context
import { useMaterialUIController } from 'context';

const DefaultNavbar = ({ routes, transparent, light, action }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [authenticationMenu, setAuthenticationMenu] = useState(false);

  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const openAuthenticationMenu = ({ currentTarget }) =>
    setAuthenticationMenu(currentTarget);
  const closeAuthenticationMenu = () => setAuthenticationMenu(false);
  const openMobileNavbar = ({ currentTarget }) =>
    setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener('resize', displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', displayMobileNavbar);
  }, []);

  return (
    <Container>
      <MDBox
        py={1}
        px={2}
        my={2}
        width="100%"
        borderRadius="lg"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
        left={0}
        zIndex={3}
        // sx={({
        //   palette: { transparent: transparentColor, white, background },
        //   functions: { rgba },
        // }) => ({
        //   backgroundColor: transparent
        //     ? transparentColor.main
        //     : rgba(darkMode ? background.sidenav : white.main, 0.8),
        //   backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        // })}
      >
        <MDBox
          component={Link}
          to="/"
          py={transparent ? 1.5 : 0.75}
          lineHeight={1}
          pl={{ xs: 0, lg: 1 }}
        >
          <img width={'80%'} src={PursuitLogo} />
        </MDBox>
        <MDBox color="inherit" display={{ xs: 'none', lg: 'flex' }} m={0} p={0}>
          <DefaultNavbarLink
            name="authentication"
            openHandler={openAuthenticationMenu}
            closeHandler={closeAuthenticationMenu}
            light={light}
          />
        </MDBox>
        {/* {action &&
          (action.type === 'internal' ? (
            <MDBox display={{ xs: 'none', lg: 'inline-block' }}>
              <MDButton
                component={Link}
                to={action.route}
                variant="gradient"
                color={action.color ? action.color : 'info'}
                size="small"
              >
                {action.label}
              </MDButton>
            </MDBox>
          ) : (
            <MDBox display={{ xs: 'none', lg: 'inline-block' }}>
              <MDButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="gradient"
                color={action.color ? action.color : 'info'}
                size="small"
                sx={{ mt: -0.3 }}
              >
                {action.label}
              </MDButton>
            </MDBox>
          ))} */}
        <MDBox
          display={{ xs: 'inline-block', lg: 'none' }}
          lineHeight={0}
          py={1.5}
          pl={1.5}
          color="#fff"
          sx={{ cursor: 'pointer' }}
          onClick={openMobileNavbar}
        >
          <Icon fontSize="default">{mobileNavbar ? 'close' : 'menu'}</Icon>
        </MDBox>
      </MDBox>

      <AuthenticationMenu
        routes={routes}
        open={authenticationMenu}
        close={closeAuthenticationMenu}
      />

      {mobileView && (
        <DefaultNavbarMobile
          routes={routes}
          open={mobileNavbar}
          close={closeMobileNavbar}
        />
      )}
    </Container>
  );
};

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(['external', 'internal']).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'dark',
        'light',
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;
