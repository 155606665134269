import { useState, useEffect, useMemo } from 'react';

// Onesignal Push Notifications
import OneSignal from 'react-onesignal';

// Fonts Load
import './fonts/Montserrat-Regular.ttf';
import './fonts/Montserrat-Black.ttf';
import './fonts/Montserrat-Light.ttf';
import './fonts/Montserrat-Thin.ttf';
import './fonts/Montserrat-Medium.ttf';
import './fonts/Montserrat-Bold.ttf';
import './fonts/Montserrat-LightItalic.ttf';

import 'index.css';

// Google Analytics
import ReactGA from 'react-ga';

// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// Helper libs
import _ from 'lodash';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

//  example components
import Sidenav from 'examples/Sidenav';

//  themes
import theme from 'assets/theme';

//  Dark Mode themes
import themeDark from 'assets/theme-dark';

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';

//  routes
import routes from 'routes';

//  contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setActiveUser,
} from 'context';

// Redux
import { useSelector } from 'react-redux';

// Images
import brandWhite from 'assets/images/logo-ct.png';
import brandDark from 'assets/images/logo-ct-dark.png';
import TermsPage from './layouts/pages/terms';
import ContactPage from './layouts/pages/support/contact';

// Google Analytics Init
const TRACKING_ID = process.env.REACT_APP_GA_KEY;
ReactGA.initialize(TRACKING_ID);

const ProtectedRoute = ({ isAllowed, redirectPath = '/sign-in', children }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

const App = () => {
  const stateUser = useSelector((state) => state.user);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    activeUser,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [user, setUser] = useState(null);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
    });
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    // Get logged in user
    if (!_.isEmpty(stateUser.data)) {
      setActiveUser(dispatch, stateUser.data);
    }
  }, [activeUser]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (
          route.key !== 'reset-password' &&
          route.key !== 'forgot-password' &&
          route.key !== 'activate-account' &&
          route.key !== 'sign-up' &&
          route.key !== 'sign-in'
        ) {
          return (
            <Route
              key={route.key}
              path={route.route}
              element={
                <ProtectedRoute
                  redirectPath="/sign-in"
                  isAllowed={
                    !_.isEmpty(stateUser.data) &&
                    stateUser.data?.data?.role === 'user'
                  }
                >
                  {route.component}
                </ProtectedRoute>
              }
            />
          );
        }

        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />

      {layout === 'dashboard' && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="Pursuit"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard/my-results" />} />
        <Route path="/privacy-policy" element={<TermsPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
