/** 
  All of the routes for the  are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

//  layouts

import PursuitIndexPage from 'layouts/dashboards/echelon';
import LeaderBoard from './layouts/dashboards/echelon/LeaderBoard';
import EventsCalendar from 'layouts/dashboards/eventsCalendar';
import ParticipationPage from 'layouts/dashboards/participation';
import WhosParticipating from './layouts/dashboards/participation/WhosParticipating';
import MyResults from 'layouts/dashboards/my-results';
import AllResults from 'layouts/dashboards/all-results';
import DistanceResults from 'layouts/dashboards/all-results/DistanceResults';
import DistanceDetails from 'layouts/dashboards/all-results/DistanceDetails';
import ProfileOverview from 'layouts/pages/profile/profile-overview';
import Preferences from 'layouts/pages/account/preferences';
import SignIn from 'layouts/authentication/sign-in';
import SignUp from 'layouts/authentication/sign-up';
import ResetPassword from 'layouts/authentication/reset-password';
import ForgotPassword from 'layouts/authentication/forgot-password';
import ActivateAccount from 'layouts/authentication/activate-account';
import TermsPage from './layouts/pages/terms';
import AboutPage from './layouts/pages/about';
import ContactPage from './layouts/pages/support/contact';

// Pursuit components
import MDAvatar from 'components/MDAvatar';

// @mui icons
import Icon from '@mui/material/Icon';

// Images
import PursuitLogo from 'assets/images/pursuit_icon_and_shadow.svg';

const routes = [
  {
    type: 'collapse',
    name: '',
    key: 'user',
    icon: <MDAvatar src={PursuitLogo} alt="User" size="sm" />,
    collapse: [
      {
        name: 'Profile',
        key: 'profile',
        route: '/profile',
        component: <ProfileOverview />,
      },
      {
        name: 'Account',
        key: 'account',
        route: '/account',
        component: <Preferences />,
      },
      {
        name: 'sign-in',
        key: 'sign-in',
        route: '/sign-in',
        component: <SignIn />,
      },
    ],
  },
  { type: 'divider', key: 'divider-0' },

  {
    name: 'Activate Account',
    key: 'activate-account',
    route: '/activate-account/:id',
    component: <ActivateAccount />,
  },
  {
    name: 'Sign-Up',
    key: 'sign-up',
    route: '/sign-up',
    component: <SignUp />,
  },
  {
    name: 'Reset Password',
    key: 'reset-password',
    route: '/reset-password/:id',
    component: <ResetPassword />,
  },
  {
    name: 'Forgot Password',
    key: 'forgot-password',
    route: '/forgot-password',
    component: <ForgotPassword />,
  },
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: 'Events Calendar',
        key: 'events-calendar',
        route: '/dashboard/events-calendar',
        component: <EventsCalendar />,
      },
      {
        name: 'Pursuit Index',
        key: 'pursuit-index',
        route: '/dashboard/pursuit-index',
        component: <PursuitIndexPage />,
      },
      {
        name: 'leaderboard',
        key: 'leaderboard',
        route: '/dashboard/leaderboard',
        component: <LeaderBoard />,
      },
      {
        name: 'Participation',
        key: 'participation',
        route: '/dashboard/participation',
        component: <ParticipationPage />,
      },
      {
        name: 'Whos Participating',
        key: 'whos-participating',
        route: '/dashboard/whos-participating',
        component: <WhosParticipating />,
      },
      {
        name: 'Results',
        key: 'all-results',
        route: '/dashboard/all-results',
        component: <AllResults />,
      },
      {
        name: 'Distance Results',
        key: 'distance-results',
        route: '/dashboard/distance-results',
        component: <DistanceResults />,
      },
      {
        name: 'Distance Details',
        key: 'distance-Details',
        route: '/dashboard/distance-Details',
        component: <DistanceDetails />,
      },
      {
        name: 'My Results',
        key: 'my-results',
        route: '/dashboard/my-results',
        component: <MyResults />,
      },
      {
        name: 'Privacy Policy',
        key: 'privacy-policy',
        route: '/dashboard/privacy-policy',
        component: <TermsPage />,
      },
      {
        name: 'About',
        key: 'about',
        route: '/dashboard/about',
        component: <AboutPage />,
      },
      {
        name: 'Contact',
        key: 'contact-us',
        route: '/dashboard/contact-us',
        component: <ContactPage />,
      },
      {
        name: 'Profile',
        key: 'profile',
        route: '/dashboard/profile',
        component: <ProfileOverview />,
      },
      {
        name: 'Account',
        key: 'account',
        route: '/dashboard/account',
        component: <Preferences />,
      },
    ],
  },
  { type: 'divider', key: 'divider-1' },
];

export default routes;
