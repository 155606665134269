import React, { useState, useEffect } from 'react';

// react-router-dom components
import { Link, useNavigate } from 'react-router-dom';

// formik components
import { Formik, Form } from 'formik';
import { PersistFormikValues } from 'formik-persist-values';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import LogoWithText from 'assets/images/LogoWithText.svg';

// Redux
import { useSelector } from 'react-redux';

// Helper methods
import { preRegisterUser } from 'auth';

// NewUser layout schemas for form and form fields
import validations from './schemas/validations';
import form from './schemas/form';
import initialValues from './schemas/initialValues';
import RegisterForm from './RegisterForm';

const SignUp = () => {
  const navigate = useNavigate();

  const [errorSB, setErrorSB] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [termsAccepted, setTermsAccepted] = useState();
  const [termsError, setTermsError] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState();
  const [privacyError, setPrivacyError] = useState(false);

  const [phoneValue, setPhoneValue] = useState('');
  const { formId, formField } = form;
  const user = useSelector((state) => state.user);

  useEffect(() => {}, [user]);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={errorMsg}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const submitForm = async (values, actions) => {
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const handleSubmit = async (values, actions) => {
    if (!termsAccepted) {
      openErrorSB();
      setErrorMsg('Please accept the terms and conditions.');
      return setTermsError(true);
    }

    if (!privacyAccepted) {
      openErrorSB();
      setErrorMsg('Please accept the privacy policy.');
      return setPrivacyError(true);
    }

    const result = await preRegisterUser({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email.toLowerCase(),
      password: values.password,
      idNumber: values.idNumber,
      passportNumber: values.passportNumber,
      mobileNumber: phoneValue,
    });

    if (!result.data.success) {
      setErrorMsg(result.data.error);
      openErrorSB();
    }

    if (result.data.success) {
      submitForm(values, actions);
      navigate('/sign-in', {
        state: {
          message: 'welcome',
        },
      });
    }
  };

  return (
    <BasicLayout>
      {renderErrorSB}

      <MDBox mx={2} p={2} mb={1} textAlign="center">
        <MDBox mx={2} p={2} mb={1} textAlign="center">
          <img src={LogoWithText} />
        </MDBox>
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Register
        </MDTypography>
      </MDBox>
      <MDBox pb={3} px={1}>
        <Formik
          initialValues={initialValues}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form id={formId} autoComplete="off">
              <MDBox>
                <RegisterForm
                  onPhoneValue={(phone) => setPhoneValue(phone)}
                  onTermsChecked={(term) => setTermsAccepted(term)}
                  onPrivacyChecked={(privacy) => setPrivacyAccepted(privacy)}
                  onTermsError={termsError}
                  onPrivacyError={privacyError}
                  formData={{
                    values,
                    touched,
                    formField,
                    errors,
                    form,
                  }}
                />
                ;
                <MDBox
                  mt={2}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDButton
                    disabled={isSubmitting}
                    type="submit"
                    variant="outlined"
                    color="info"
                    fullWidth
                  >
                    Register
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox mt={3} textAlign="center">
                <MDTypography variant="button" color="text">
                  Already registered?&nbsp;&nbsp;
                  <MDTypography
                    component={Link}
                    to="/sign-in"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                  >
                    Login
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Want to get in touch?&nbsp;&nbsp;
                  <MDTypography
                    component={Link}
                    to="/contact-us"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                  >
                    Contact Us
                  </MDTypography>
                </MDTypography>
              </MDBox>

              <PersistFormikValues
                name="user-registration-form"
                ignoreValues={['firstName', 'lastName', 'email', 'idNumber']}
              />
            </Form>
          )}
        </Formik>
      </MDBox>
    </BasicLayout>
  );
};

export default SignUp;
