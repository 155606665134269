import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from 'App';
import './assets/loader.css';

// PWA setup
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ReactPWAInstallProvider from 'react-pwa-install';

// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';

// Router
import { createBrowserHistory } from 'history';

// Soft UI Context Provider
import { MaterialUIControllerProvider } from 'context';

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ReactPWAInstallProvider enableLogging>
        <HashRouter basename="/" history={hist}>
          <MaterialUIControllerProvider>
            <App />
          </MaterialUIControllerProvider>
        </HashRouter>
      </ReactPWAInstallProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
