//  example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import EventsCalendar from './EventsCalendar';

const Calendar = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar userName />
      <EventsCalendar />
    </DashboardLayout>
  );
};

export default Calendar;
