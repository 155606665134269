import React, { useState, useEffect } from 'react';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDSnackbar from 'components/MDSnackbar';

// formik components
import { Formik, Form } from 'formik';

import { useSelector, useDispatch } from 'react-redux';
import { updateUserPassword } from 'actions/userActions';

// NewUser layout schemas for form and form fields
import validations from './schemas/validations';
import form from './schemas/form';
import initialValues from './schemas/initialValues';
import ChangePasswordForm from './ChangePasswordForm';
// import InstallPWA from "../../../../../../components/InstallPWA";

const ChangePassword = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const token = user.data.token;

  const [errorSB, setErrorSB] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [successSB, setSuccessSB] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');

  const { userInfo } = user.data;
  const { formId, formField } = form;

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content="Something went wrong, please try again."
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success"
      content="Your password is now updated."
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const passwordRequirements = [
    'One special characters',
    'Min 6 characters',
    'One number (2 are recommended)',
    'Change it often',
  ];

  const submitForm = async (values, actions) => {
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const handleSubmit = async (values, actions) => {
    const result = await updateUserPassword({
      token,
      currentPassword: values.password,
      newPassword: values.newPassword,
    });

    if (!result.data.success) {
      setErrorMsg(result.data.error);
      openErrorSB();
    }

    if (result.data.success) {
      openSuccessSB();
      submitForm(values, actions);
    }
  };

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox
        key={itemKey}
        component="li"
        color="text"
        fontSize="1.25rem"
        lineHeight={1}
      >
        <MDTypography
          variant="button"
          color="text"
          fontWeight="regular"
          verticalAlign="middle"
        >
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <>
      {renderErrorSB}
      {renderSuccessSB}
      {/* <MDBox p={0}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox> */}
      <MDBox pb={0} px={0}>
        <MDBox pt={0} pb={1} px={0}>
          <Formik
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <MDBox>
                  <ChangePasswordForm
                    formData={{
                      values,
                      touched,
                      formField,
                      errors,
                    }}
                  />
                  <MDBox
                    mt={1}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <MDButton
                      disabled={isSubmitting}
                      type="submit"
                      variant="outlined"
                      color="info"
                      fullWidth
                    >
                      Change Password
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </MDBox>
        {/* <MDBox mt={6} mb={1}>
          <MDTypography variant="h5">Password requirements</MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="body2" color="text">
            Please follow this guide for a strong password
          </MDTypography>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </MDBox>
        </MDBox> */}
      </MDBox>
    </>
  );
};

export default ChangePassword;
