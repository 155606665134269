// prop-type is a library for typechecking of props
import PropTypes from 'prop-types';

// Pursuit components
import MDBox from 'components/MDBox';

import { FormControl, FormControlLabel, FormHelperText } from '@mui/material';

import { Link } from 'react-router-dom';

// Returning User page components
import FormField from 'layouts/pages/users/new-user/components/FormField';

const SignInForm = ({ formData }) => {
  const { formField, values, errors, touched } = formData;
  const { email, password } = formField;
  const { email: emailV, password: passwordV } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <MDBox mb={2}>
          <FormField
            type={email.type}
            label={email.label}
            name={email.name}
            value={emailV}
            placeholder={email.placeholder}
            error={errors.email && touched.email}
            success={emailV.length > 0 && !errors.email}
          />
        </MDBox>
        <MDBox mb={2}>
          <FormField
            type={password.type}
            label={password.label}
            name={password.name}
            value={passwordV}
            placeholder={password.placeholder}
            error={errors.password && touched.password}
            success={passwordV.length > 0 && !errors.password}
          />
          <MDBox sx={{ width: '100%', textAlign: 'right' }}>
            <Link
              style={{
                color: '#9B9B9B',
                fontSize: 14,
              }}
              to={'/forgot-password'}
            >
              Forgot Password?
            </Link>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

// typechecking props for SignInForm
SignInForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default SignInForm;
