import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import MDBox from 'components/MDBox';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import StickyNav from 'examples/Navbars/StickyNav';
import typography from '../../../../assets/theme/base/typography';

// Assets
import ResultBackgroundSVG from 'assets/images/myresultsbeacon-01.svg';
import LookupButtonSVG from 'assets/images/look-up-button-01.svg';

// Helpers
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Icons
import MountainBikingIcon from 'assets/images/mountainbike-01.svg';
import RoadCyclingIcon from 'assets/images/roadcycling-01.svg';
import TrailRunningIcon from 'assets/images/trailrunning-01.svg';
import RoadRunningIcon from 'assets/images/roadrunning-01.svg';
import SwimRunIcon from 'assets/images/swimrun-01.svg';
import ObstacleCourseIcon from 'assets/images/obstaclecourse-01.svg';
import SwimIcon from 'assets/images/swim-01.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DetailsDialog = ({ data, isOpen = false }) => {
  const user = useSelector((state) => state.user);
  const [open, setOpen] = React.useState(isOpen);
  const navigate = useNavigate();
  const { size } = typography;

  const { userInfo } = user.data;

  const userGender = userInfo[0].Gender__pc;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const generateIcon = (discipline) => {
    switch (discipline) {
      case 'Road Running':
        return RoadRunningIcon;
      case 'Road Cycling':
        return RoadCyclingIcon;
      case 'Trail Running':
        return TrailRunningIcon;
      case 'Mountain Biking':
        return MountainBikingIcon;
      case 'Swimrun':
        return SwimRunIcon;
      case 'Swim':
        return SwimIcon;
      default:
        return ObstacleCourseIcon;
    }
  };

  return (
    <>
      {!isOpen && (
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClickOpen}
          aria-label="open"
          sx={{
            padding: 0,
            marginBottom: 0.5,
            t: 1,
            marginRight: 1,
            marginLeft: 0,
            marginTop: '-2px',
          }}
        >
          <img width={30} src={LookupButtonSVG} />
        </IconButton>
      )}

      <Dialog
        fullScreen
        open={open}
        style={{ background: '#22222' }}
        color="dark"
        scroll="body"
        onClose={handleClose}
        // TransitionComponent={Transition}
      >
        <DashboardNavbar userName onBackClick={!isOpen ? handleClose : null} />
        <MDBox
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 1.5rem',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              width: '100%',
              mt: 3,
              fontSize: '1.5rem',
              fontWeight: 100,
              fontFamily: 'MontserratItal',
              lineHeight: 'normal',
            }}
            autoFocus
            variant="h6"
            color="#ffffff"
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                return navigate('/dashboard/distance-details', {
                  state: {
                    distanceId: data.Distance__r.Id,
                    distanceName: data.Distance_Name__c,
                  },
                });
              }}
              aria-label="open"
              sx={{
                padding: 0,
                width: '100%',
                marginRight: 1,
                marginLeft: 2,
              }}
            >
              <img width={30} src={LookupButtonSVG} />
            </IconButton>
            <em>
              {data.Event_Name__c
                ? data.Event_Name__c.replace(/(<([^>]+)>)/gi, '')
                : data.Event_Name_Report__c}
            </em>
          </Typography>

          <Typography
            sx={{
              textAlign: 'center',
              width: '100%',
              textTransform: 'uppercase',
              fontSize: '0.8rem',
              fontWeight: 300,
              fontFamily: 'MontserratBold',
            }}
            variant="h6"
            color="secondary"
          >
            {data.Distance_Name__c ? data.Distance_Name__c : 'N/A'}
          </Typography>
        </MDBox>
        <MDBox
          maxWidth="md"
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 1.5rem',
            margin: '0 auto',
          }}
        >
          <MDBox sx={{ width: '100%' }}>
            <List
              sx={{
                background: '#222222',
                padding: {
                  xs: '0 1.5rem',
                  sm: '0 5.5rem',
                  md: '0 2.5rem',
                  lg: '0 2.5rem',
                },
              }}
            >
              {data.Distance_KM__c && (
                <Grid
                  mt={2}
                  container
                  justifyContent="space-between"
                  alignItems="space-between"
                >
                  <Grid
                    item
                    sx={{
                      color: '#70FAFD',
                      fontSize: size.md,
                      fontWeight: 400,
                    }}
                  >
                    Distance (km)
                  </Grid>
                  <Grid
                    item
                    sx={{
                      color: '#70FAFD',
                      fontSize: size.md,
                      fontWeight: 400,
                    }}
                  >
                    {data.Distance_KM__c}
                  </Grid>
                </Grid>
              )}
              {data.Distance__r.Number_of_positions__c && (
                <Grid
                  mt={1}
                  container
                  justifyContent="space-between"
                  alignItems="space-between"
                >
                  <Grid
                    item
                    style={{
                      color: '#70FAFD',
                      fontSize: size.md,
                      fontWeight: 400,
                    }}
                  >
                    Position
                  </Grid>
                  <Grid item sx={{ fontSize: size.md, fontWeight: 400 }}>
                    <Typography variant="p" color="secondary">
                      {data.Position__c ? `${data.Position__c}` : ''}
                    </Typography>
                    <Typography variant="p" color="primary">
                      /{data.Distance__r.Number_of_positions__c}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {data.Position_Gender__c && (
                <Grid
                  mt={1}
                  container
                  justifyContent="space-between"
                  alignItems="space-between"
                >
                  <Grid
                    item
                    sx={{
                      color: '#70FAFD',
                      fontSize: size.md,
                      fontWeight: 400,
                    }}
                  >
                    Gender
                  </Grid>
                  <Grid item sx={{ fontSize: size.md, fontWeight: 400 }}>
                    <Typography variant="p" color="secondary">
                      {data.Position_Gender__c}
                    </Typography>
                    <Typography variant="p" color="primary">
                      /
                      {userGender === 'Male'
                        ? data.Distance__r.Total_Male__c
                        : data.Distance__r.Total_Female__c}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {data.Position_Category__c && (
                <Grid
                  mt={1}
                  container
                  justifyContent="space-between"
                  alignItems="space-between"
                >
                  <Grid
                    item
                    sx={{
                      color: '#70FAFD',
                      fontSize: size.md,
                      fontWeight: 400,
                    }}
                  >
                    Category Position
                  </Grid>
                  <Grid
                    item
                    sx={{
                      color: '#70FAFD',
                      fontSize: size.md,
                      fontWeight: 400,
                    }}
                  >
                    {data.Position_Category__c}
                  </Grid>
                </Grid>
              )}
              {data.Position_Batch__c && (
                <Grid
                  mt={1}
                  container
                  justifyContent="space-between"
                  alignItems="space-between"
                >
                  <Grid
                    item
                    sx={{
                      color: '#70FAFD',
                      fontSize: size.md,
                      fontWeight: 400,
                    }}
                  >
                    Batch Position
                  </Grid>
                  <Grid
                    item
                    sx={{
                      color: '#70FAFD',
                      fontSize: size.md,
                      fontWeight: 400,
                    }}
                  >
                    {data.Position_Batch__c}
                  </Grid>
                </Grid>
              )}
              {data.Actual_Batch__r && (
                <Grid
                  mt={1}
                  container
                  justifyContent="space-between"
                  alignItems="space-between"
                >
                  <Grid
                    item
                    sx={{
                      color: '#70FAFD',
                      fontSize: size.md,
                      fontWeight: 400,
                    }}
                  >
                    Batch
                  </Grid>
                  <Grid
                    item
                    sx={{
                      color: '#70FAFD',
                      fontSize: size.md,
                      fontWeight: 400,
                    }}
                  >
                    {data.Actual_Batch__r.Name}
                  </Grid>
                </Grid>
              )}
            </List>
          </MDBox>
          <MDBox
            sx={{
              width: '100%',
              height: '320px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',

              mb: 4,
              mt: 4,
              marginBottom: { xs: 10, sm: 0, lg: 0 },
            }}
          >
            <MDBox
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${ResultBackgroundSVG})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              <img
                width={50}
                src={generateIcon(data.Distance__r.Discipline__c)}
              />
              {data.Category__r && (
                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: 'center',
                    width: '100%',
                    textTransform: 'uppercase',
                    fontSize: { xs: size.sm, md: size.md },
                    fontWeight: 300,
                    fontFamily: 'TriakisFont-SemiBold',
                    color: '#000',
                  }}
                  variant="h6"
                  color="primary"
                >
                  {data.Category__r.Name}
                </Typography>
              )}
              {!data.Category__r && (
                <Typography
                  sx={{
                    mt: 0.5,
                    textAlign: 'center',
                    width: '100%',
                    textTransform: 'uppercase',
                    fontSize: { xs: size.sm, md: size.md },
                    fontWeight: 300,
                    fontFamily: 'TriakisFont-SemiBold',
                    color: '#000',
                  }}
                  variant="h6"
                  color="primary"
                >
                  <br />
                </Typography>
              )}
              {data.Actual_Time_in_Text__c && (
                <Typography
                  sx={{
                    mb: 0,
                    mt: 0,
                    textAlign: 'center',
                    width: '100%',
                    textTransform: 'uppercase',
                    fontSize: '2rem',
                    fontWeight: 300,
                    lineHeight: '1',
                    fontFamily: 'TriakisFont-Heavy',
                    color: '#000',
                  }}
                  variant="h6"
                  color="primary"
                >
                  {data.Actual_Time_in_Text__c}
                </Typography>
              )}
              {data.Position_Category__c && (
                <>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      width: '100%',
                      mb: 0,
                      textTransform: 'uppercase',
                      fontSize: '0.6rem',
                      fontWeight: 300,
                      fontFamily: 'TriakisFont-SemiBold',
                      color: '#000',
                    }}
                    variant="h6"
                    color="primary"
                  >
                    Category Position
                  </Typography>
                  <Typography
                    sx={{
                      mt: 0,
                      textAlign: 'center',
                      width: '100%',
                      textTransform: 'uppercase',
                      fontSize: '0.8rem',
                      fontWeight: 300,
                      fontFamily: 'TriakisFont-Heavy',
                      color: '#000',
                    }}
                    variant="h6"
                    color="primary"
                  >
                    {data.Position_Category__c}
                  </Typography>
                </>
              )}

              {!data.Position_Category__c && data.Position__c && (
                <>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      width: '100%',
                      mb: 0,
                      textTransform: 'uppercase',
                      fontSize: '0.6rem',
                      fontWeight: 300,
                      fontFamily: 'TriakisFont-SemiBold',
                      color: '#000',
                    }}
                    variant="h6"
                    color="primary"
                  >
                    Overall Position
                  </Typography>
                  <Typography
                    sx={{
                      mt: 0,
                      textAlign: 'center',
                      width: '100%',
                      textTransform: 'uppercase',
                      fontSize: '0.8rem',
                      fontWeight: 300,
                      fontFamily: 'TriakisFont-Heavy',
                      color: '#000',
                    }}
                    variant="h6"
                    color="primary"
                  >
                    {data.Position__c}
                  </Typography>
                </>
              )}
              {!data.Position_Category__c && !data.Position__c && (
                <>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      width: '100%',
                      mb: 0,
                      textTransform: 'uppercase',
                      fontSize: '0.6rem',
                      fontWeight: 300,
                      fontFamily: 'TriakisFont-SemiBold',
                      color: '#000',
                    }}
                    variant="h6"
                    color="primary"
                  >
                    <br />
                  </Typography>
                  <Typography
                    sx={{
                      mt: 0,
                      textAlign: 'center',
                      width: '100%',
                      textTransform: 'uppercase',
                      fontSize: '0.8rem',
                      fontWeight: 300,
                      fontFamily: 'TriakisFont-Heavy',
                      color: '#000',
                    }}
                    variant="h6"
                    color="primary"
                  >
                    <br />
                  </Typography>
                </>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
        <StickyNav />
      </Dialog>
    </>
  );
};

export default DetailsDialog;
