import React, { useState } from 'react';

// react-router-dom components
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Helper methods
import { resetPasswordRequest } from '../../../actions/userActions';

// formik components
import { Formik, Form } from 'formik';

// Reset password layout schemas for form and form fields
import validations from './schemas/validations';
import form from './schemas/form';
import initialValues from './schemas/initialValues';
import ResetPasswordForm from './ResetPasswordForm';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import MDSnackbar from '../../../components/MDSnackbar';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Assets
import LogoWithText from 'assets/images/LogoWithText.svg';
import Loader from '../../../components/Loader';

const ResetPassword = () => {
  const navigate = useNavigate();
  const params = useParams();

  const user = useSelector((state) => state.user);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={errorMsg}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
    />
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Reset Link Sent"
      content="We have sent you a reset password link to your email. Please check your spam if you do not see any email from Pursuit."
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
    />
  );

  const { formId, formField } = form;

  const handleSubmit = async (values, actions) => {
    setLoading(true);
    const resetToken = params.id;

    const result = await resetPasswordRequest(resetToken, values.password);

    if (!result.data.success) {
      setLoading(false);
      setErrorMsg(result.data.error);
      openErrorSB();
    }

    if (result.data.success) {
      setLoading(false);
      actions.setSubmitting(false);

      navigate('/sign-in', {
        state: {
          message: 'password-reset',
        },
      });
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <BasicLayout>
      {renderSuccessSB}
      {renderErrorSB}

      <MDBox mx={2} p={2} mb={1} textAlign="center">
        <MDBox mx={2} p={2} mb={1} textAlign="center">
          <img src={LogoWithText} />
        </MDBox>

        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Reset Password
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <Formik
          initialValues={initialValues}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form id={formId} autoComplete="off">
              <MDBox>
                <ResetPasswordForm
                  formData={{
                    values,
                    touched,
                    formField,
                    errors,
                  }}
                />
                ;
                <MDBox
                  mt={2}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDButton
                    disabled={isSubmitting}
                    type="submit"
                    variant="outlined"
                    color="info"
                    fullWidth
                  >
                    Reset Password
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Already have an account?&nbsp;&nbsp;
                  <MDTypography
                    component={Link}
                    to="/sign-in"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                  >
                    Login
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </Form>
          )}
        </Formik>
      </MDBox>
    </BasicLayout>
  );
};

export default ResetPassword;
