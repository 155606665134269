/** 
  All of the routes for the page layout of  are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the DefaultNavbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `name` key is used for the name of the route on the DefaultNavbar.
  3. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  4. The `icon` key is used for the icon of the route on the DefaultNavbar, you have to add a node.
  5. The `collapse` key is used for making a collapsible item on the DefaultNavbar that contains other routes inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  6. The `route` key is used to store the route location which is used for the react router.
  7. The `href` key is used to store the external links location.
*/

// @mui material components
import Icon from '@mui/material/Icon';

const pageRoutes = [
  {
    name: 'Dashboards',
    key: 'dashboards',
    icon: <Icon>dashboard</Icon>,
    collapse: [
      {
        name: 'Sales',
        key: 'sales',
        route: '/dashboards/sales',
      },
    ],
  },

  {
    name: 'Profile',
    key: 'profile',
    icon: <Icon>badge</Icon>,
    collapse: [
      {
        name: 'Profile',
        key: 'profile-overview',
        route: '/profile',
      },
    ],
  },
  {
    name: 'Account',
    key: 'account',
    icon: <Icon>account_balance</Icon>,
    collapse: [
      {
        name: 'Settings',
        key: 'setting',
        route: '/pages/account/setting',
      },
    ],
  },

  {
    name: 'Login',
    key: 'sign-in',
    icon: <Icon>login</Icon>,
    collapse: [
      {
        name: 'Basic',
        key: 'basic',
        route: '/sign-in',
      },
    ],
  },
  {
    name: 'Activate Account',
    key: 'activate-account',
    icon: <Icon>Activate</Icon>,
    collapse: [
      {
        name: 'Basic',
        key: 'basic',
        route: '/activate-account',
      },
    ],
  },
  {
    name: 'Register',
    key: 'sign-up',
    icon: <Icon>assignment</Icon>,

    collapse: [
      {
        name: 'Cover',
        key: 'cover',
        route: '/sign-up',
      },
    ],
  },
  {
    name: 'Forgot Password',
    key: 'forgot-password',
    icon: <Icon>restart_alt</Icon>,
    collapse: [
      {
        name: 'Cover',
        key: 'cover',
        route: '/forgot-password',
      },
    ],
  },
];

export default pageRoutes;
