import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI || 'http://localhost:8000/api/v1',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  responseType: 'json',
});

export default api;
