// @mui material components
import Grid from '@mui/material/Grid';

// Pursuit components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

// Preferences page components
import PursuitIcon from 'assets/images/pursuit_icon_outline.svg';
import PursuitLogo from 'assets/images/pursuitlogoloading-01.svg';
import Sidenav from 'layouts/pages/account/preferences/components/Sidenav';
import Header from 'layouts/pages/account/preferences/components/Header';
import BasicInfo from 'layouts/pages/account/preferences/components/BasicInfo';
import ChangePassword from 'layouts/pages/account/preferences/components/ChangePassword';
import Authentication from 'layouts/pages/account/preferences/components/Authentication';
import DeleteAccount from 'layouts/pages/account/preferences/components/DeleteAccount';
import NavTabs from 'layouts/pages/profile/components/NavTabs';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import IOSInstructions from 'components/IOSInstructions';
import { isIOS } from 'react-device-detect';

import { useReactPWAInstall } from 'react-pwa-install';

const Preferences = () => {
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  const handleClick = () => {
    pwaInstall({
      title: 'Install Pursuit App',
      logo: PursuitIcon,
      description: 'Get the latest updates on your results.',
    });
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar userName />

        <MDBox
          mt={0}
          px={3}
          style={{
            background: '#222222',
          }}
        >
          <Grid container mt={2}>
            <MDBox
              component="img"
              src={PursuitLogo}
              justifyContent="center"
              sx={{ margin: '0 auto 20px', display: { lg: 'none' } }}
              alt="Pursuit"
              width="60%"
            />

            <Grid item xs={12} lg={12}>
              <MDBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} justifyContent="center">
                    {supported() && !isInstalled() && (
                      <MDBox
                        mt={3}
                        mb={4}
                        textAlign="center"
                        style={{ justifyContent: 'center' }}
                      >
                        {isIOS && <IOSInstructions />}
                        {!isIOS && (
                          <MDButton
                            onClick={handleClick}
                            variant="outlined"
                            color="info"
                          >
                            Install Pursuit App
                          </MDButton>
                        )}
                      </MDBox>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <ChangePassword />
                  </Grid>
                  {/* <Grid item xs={12}>
                  <Authentication />
                </Grid> */}
                  <Grid item xs={12}>
                    <DeleteAccount />
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default Preferences;
