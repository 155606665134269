// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Base Styles
import typography from 'assets/theme/base/typography';

// Pursuit components
import MDTypography from 'components/MDTypography';

function DefaultCell({ children, color = 'info' }) {
  const { size } = typography;
  return (
    <MDTypography
      sx={{ fontSize: { xs: size.xs, sm: size.sm, md: size.md, lg: size.lg } }}
      align="top"
      variant="button"
      fontWeight="light"
      color={color}
    >
      {children}
    </MDTypography>
  );
}

// Typechecking props for the DefaultCell
DefaultCell.propTypes = {
  children: PropTypes.any.isRequired,
};

export default DefaultCell;
