import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { email, password },
} = checkout;

export default Yup.object().shape({
  [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
  [password.name]: Yup.string().required(password.errorMsg),
});
