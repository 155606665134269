import { useState, useEffect } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import { IconButton } from '@mui/material';

// Pursuit components
import MDBox from 'components/MDBox';

// Data components
import DataTable from 'examples/Tables/DataTable';
import DefaultCell from 'layouts/dashboards/components/DefaultCell';
import DisciplineCell from 'layouts/dashboards/components/DisciplineCell';
import DetailsDialog from './DetailsDialog';

// Redux
import { useSelector } from 'react-redux';

import api from 'api';
import _ from 'lodash';
import Loader from '../../../components/Loader';

// Assets
import LookupButtonSVG from 'assets/images/look-up-button-01.svg';

const EventsCalendar = () => {
  // Get user for dashboard data
  const user = useSelector((state) => state.user);
  const token = user.data.token;
  const [loading, setLoading] = useState(false);
  const [myEvents, setMyEvents] = useState([]);
  const [eventData, setEventData] = useState([]);

  const [eventsResultData, setEventsResultData] = useState({
    columns: [],
    rows: [],
  });
  const [myEventsResultData, setMyEventsResultData] = useState({
    columns: [],
    rows: [],
  });

  const fetchEventsCalendar = async () => {
    setLoading(true);
    const data = await api.get('/events/faces-events', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const myData = await api.get('/events/upcoming', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const myEvents = _.uniqBy(myData.data.data, 'Event_Name_Report__c');
    const events = data.data.data;
    setEventData(events);
    setMyEvents(myEvents);
  };

  useEffect(() => {
    if (eventData.length === 0) {
      fetchEventsCalendar();
    }
  }, []);

  const removeDateFromName = (str) => {
    return str.slice(5);
  };

  const generateEventsResults = () => {
    const columns = [
      { Header: 'UPCOMING EVENTS', accessor: 'Name', width: '65%' },
      { Header: 'DATE', accessor: 'Start_Date__c', width: '50%' },
      {
        Header: 'ENTER',
        accessor: 'Discipline__c',
        width: '5%',
        align: 'right',
      },
    ];

    const rows = eventData.map((item) => {
      return {
        Name: item.Name ? (
          <DefaultCell>{removeDateFromName(item.Name)}</DefaultCell>
        ) : (
          ''
        ),
        Start_Date__c: item.Start_Date__c ? (
          <DefaultCell>{item.Start_Date__c}</DefaultCell>
        ) : (
          ''
        ),
        Discipline__c: (
          <DisciplineCell
            eventLink={item.Entry_Link__c ? item.Entry_Link__c : ''}
            image={item.Discipline__c}
          />
        ),
      };
    });

    setEventsResultData({
      columns,
      rows,
    });

    setLoading(false);
  };

  const generateMyEventsResults = () => {
    const columns = [
      { Header: 'My EVENTS', accessor: 'Name', width: '65%' },
      { Header: 'DATE', accessor: 'Date__c', width: '45%' },
      {
        Header: '',
        accessor: 'Actual_Batch__r',
        width: '10%',
        align: 'center',
      },
    ];

    const rows = myEvents.map((item) => {
      return {
        Name: item.Event_Name_Report__c ? (
          <DefaultCell color="primary">
            {removeDateFromName(item.Event_Name_Report__c)}{' '}
            {item.Distance_Name__c ? item.Distance_Name__c : ''}
          </DefaultCell>
        ) : (
          ''
        ),
        Date__c: item.Date__c ? (
          <DefaultCell color="primary">{item.Date__c}</DefaultCell>
        ) : (
          ''
        ),
        Actual_Batch__r: item.Actual_Batch__r ? (
          <DefaultCell color="primary">{item.Actual_Batch__r.Name}</DefaultCell>
        ) : (
          <DefaultCell color="primary">TBC</DefaultCell>
        ),
      };
    });

    setMyEventsResultData({
      columns,
      rows,
    });

    setLoading(false);
  };

  useEffect(() => {
    if (eventData.length > 0) {
      generateEventsResults();
    }
    if (myEvents.length > 0) {
      generateMyEventsResults();
    }
  }, [eventData, myEvents]);

  if (loading) {
    return (
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox
              pt={50}
              px={3}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Loader />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  return (
    <MDBox>
      {myEventsResultData.rows.length > 0 && (
        <Grid item xs={12}>
          <MDBox py={1} px={2}>
            <DataTable
              table={myEventsResultData}
              entriesPerPage={false}
              showTotalEntries={false}
              isSorted={false}
              noEndBorder
            />
          </MDBox>
          <MDBox
            style={{
              borderBottom: '1px dashed #70FAFD',
              padding: '1rem',
              margin: '0 1rem',
            }}
          />
        </Grid>
      )}

      {eventsResultData.rows.length > 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox py={1} px={2}>
              <DataTable
                table={eventsResultData}
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={false}
                noEndBorder
              />
            </MDBox>
          </Grid>
        </Grid>
      )}
    </MDBox>
  );
};

export default EventsCalendar;
