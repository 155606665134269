// prop-type is a library for typechecking of props
import PropTypes from 'prop-types';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Helpers
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Link } from 'react-router-dom';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
} from '@mui/material';
// NewUser page components
import FormField from 'layouts/pages/users/new-user/components/FormField';
import { useState } from 'react';

function RegisterForm({
  formData,
  onTermsChecked,
  onTermsError,
  onPrivacyChecked,
  onPrivacyError,
  onPhoneValue,
}) {
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedPrivacy, setCheckedPrivacy] = useState(false);
  const [rsaIDField, setRsaIdField] = useState(false);
  const [passportField, setPassportField] = useState(false);
  const [phoneValue, setPhoneValue] = useState(0);

  const { formField, values, errors, touched } = formData;
  const {
    firstName,
    lastName,
    email,
    repeatEmail,
    password,
    repeatPassword,
    passportNumber,
    idNumber,
  } = formField;
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    repeatEmail: repeatEmailV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
    idNumber: idNumberV,
    passportNumber: passportNumberV,
  } = values;

  const handleToggle = () => {
    setCheckedTerms(!checkedTerms);
    onTermsChecked(!checkedTerms);
  };

  const handlePrivacyToggle = () => {
    setCheckedPrivacy(!checkedPrivacy);
    onPrivacyChecked(!checkedPrivacy);
  };

  const handleToggleID = (id) => {
    if (id === 'rsa') {
      setRsaIdField(true);
      setPassportField(false);
    }

    if (id === 'passport') {
      setRsaIdField(false);
      setPassportField(true);
    }
  };

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <FormField
          type={firstName.type}
          label={firstName.label}
          name={firstName.name}
          value={firstNameV}
          placeholder={firstName.placeholder}
          error={errors.firstName && touched.firstName}
          success={firstNameV.length > 0 && !errors.firstName}
        />

        <FormField
          type={lastName.type}
          label={lastName.label}
          name={lastName.name}
          value={lastNameV}
          placeholder={lastName.placeholder}
          error={errors.lastName && touched.lastName}
          success={lastNameV.length > 0 && !errors.lastName}
        />
        <FormField
          type={email.type}
          label={email.label}
          name={email.name}
          value={emailV}
          placeholder={email.placeholder}
          error={errors.email && touched.email}
          success={emailV.length > 0 && !errors.email}
        />
        <FormField
          type={repeatEmail.type}
          label={repeatEmail.label}
          name={repeatEmail.name}
          value={repeatEmailV}
          placeholder={repeatEmail.placeholder}
          error={errors.repeatEmail && touched.repeatEmail}
          success={repeatEmailV.length > 0 && !errors.repeatEmail}
          inputProps={{ autoComplete: '' }}
        />

        <MDBox sx={{ mb: 3, mt: 3 }}>
          <MDTypography style={{ fontSize: '0.75rem' }} color="text">
            Mobile Number
          </MDTypography>
          <PhoneInput
            country={'za'}
            value={phoneValue}
            onChange={(phone) => {
              onPhoneValue(phone);
              setPhoneValue(phone);
            }}
          />
        </MDBox>

        <MDBox sx={{ mb: 3, mt: 3 }}>
          <FormControl size="small">
            <FormControlLabel
              control={
                <Radio
                  label="RSA ID Number"
                  name="rsa-id-number"
                  checked={rsaIDField}
                  onClick={() => handleToggleID('rsa')}
                />
              }
              label="RSA ID Number"
            />
          </FormControl>
          <FormControl size="small">
            <FormControlLabel
              control={
                <Radio
                  label="Passport Number"
                  name="passport-number"
                  checked={passportField}
                  onClick={() => handleToggleID('passport')}
                />
              }
              label="Passport Number"
            />
          </FormControl>

          {rsaIDField && (
            <FormField
              type={idNumber.type}
              label={idNumber.label}
              name={idNumber.name}
              value={idNumberV}
              placeholder={idNumber.placeholder}
              error={errors.idNumber && touched.idNumber}
              success={idNumberV.length > 0 && !errors.idNumber}
              inputProps={{ autoComplete: '' }}
            />
          )}

          {passportField && (
            <FormField
              type={passportNumber.type}
              label={passportNumber.label}
              name={passportNumber.name}
              value={passportNumberV}
              placeholder={passportNumber.placeholder}
              error={errors.passportNumber && touched.passportNumber}
              success={passportNumberV.length > 0 && !errors.passportNumber}
              inputProps={{ autoComplete: '' }}
            />
          )}
        </MDBox>

        <FormField
          type={password.type}
          label={password.label}
          name={password.name}
          value={passwordV}
          placeholder={password.placeholder}
          error={errors.password && touched.password}
          success={passwordV.length > 0 && !errors.password}
          inputProps={{ autoComplete: '' }}
        />
        <FormField
          type={repeatPassword.type}
          label={repeatPassword.label}
          name={repeatPassword.name}
          value={repeatPasswordV}
          placeholder={repeatPassword.placeholder}
          error={errors.repeatPassword && touched.repeatPassword}
          success={repeatPasswordV.length > 0 && !errors.repeatPassword}
          inputProps={{ autoComplete: '' }}
        />
        <MDBox display="flex" alignItems="center">
          <FormControl required error={onTermsError} size="small">
            <FormControlLabel
              control={
                <Radio
                  label=""
                  name="name"
                  checked={checkedTerms}
                  onClick={handleToggle}
                />
              }
              label=""
            />
          </FormControl>

          <MDTypography
            variant="button"
            fontWeight="regular"
            color="info"
            sx={{ cursor: 'pointer', userSelect: 'none', ml: -3 }}
          >
            &nbsp;&nbsp;I Accept&nbsp;
          </MDTypography>

          <MDTypography
            component={Link}
            to="/terms"
            variant="button"
            color="primary"
            fontWeight="medium"
          >
            Terms &amp; Conditions
          </MDTypography>
        </MDBox>
        {!checkedTerms && onTermsError && (
          <FormHelperText
            error={onTermsError}
            fontWeight="regular"
            color="error"
          >
            Please accept the terms and conditions.
          </FormHelperText>
        )}
        <MDBox display="flex" alignItems="center">
          <FormControl required error={onPrivacyError} size="small">
            <FormControlLabel
              control={
                <Radio
                  label=""
                  name="name"
                  checked={checkedPrivacy}
                  onClick={handlePrivacyToggle}
                />
              }
              label=""
            />
          </FormControl>

          <MDTypography
            variant="button"
            fontWeight="regular"
            color="info"
            sx={{ cursor: 'pointer', userSelect: 'none', ml: -3 }}
          >
            &nbsp;&nbsp;I Accept&nbsp;
          </MDTypography>

          <MDTypography
            component={Link}
            to="/privacy-policy"
            variant="button"
            color="primary"
            fontWeight="medium"
          >
            Privacy Policy
          </MDTypography>
        </MDBox>
        {!checkedPrivacy && onPrivacyError && (
          <FormHelperText
            error={onPrivacyError}
            fontWeight="regular"
            color="error"
          >
            Please accept the privacy policy.
          </FormHelperText>
        )}
      </MDBox>
    </MDBox>
  );
}

// typechecking props for RegisterForm
RegisterForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RegisterForm;
