import { useEffect } from 'react';

// react-router-dom components
import { useLocation } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// Pursuit components
import MDBox from 'components/MDBox';

//  context
import { useMaterialUIController, setLayout } from 'context';

import StickyNav from '../../Navbars/StickyNav';

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();

  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'dashboard');
  }, [pathname]);

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        px: 0,
        [breakpoints.up('md')]: {
          px: 2,
        },
      })}
    >
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 0,
          pb: 15,
          position: 'relative',
          [breakpoints.up('md')]: {
            p: 2,
            marginLeft: pxToRem(274),
            transition: transitions.create(['margin-left', 'margin-right'], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {children}
      </MDBox>

      <MDBox
        sx={({ breakpoints }) => ({
          position: 'absolute',
          bottom: 0,
          width: '100%',

          [breakpoints.up('md')]: {
            display: 'none',
          },
        })}
      >
        <StickyNav />
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
