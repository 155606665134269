import React, { useState } from 'react';

// @mui material components
import Card from '@mui/material/Card';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { deleteAccount, logout } from 'actions/userActions';

import { useSelector } from 'react-redux';

const DeleteAccount = () => {
  const user = useSelector((state) => state.user);
  const token = user.data.token;
  const [alert, setAlert] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    return deleteAccount(token).then((res) => {
      if (res.status === 200) {
        logout();
      } else {
        // TODO: show errors
        console.log('issue');
      }
    });
  };

  return (
    <MDBox
      pb={3}
      display="flex"
      justifyContent="space-between"
      alignItems={{ sm: 'center' }}
      flexDirection={{ xs: 'column', sm: 'row' }}
    >
      <MDBox p={0} lineHeight={1} style={{ textAlign: 'center' }}>
        {/* <MDBox mb={1}>
          <MDTypography variant="h5">Delete Account</MDTypography>
        </MDBox> */}
        <MDTypography variant="button" color="text">
          Once you delete your account, there is no going back. Please be
          certain.
        </MDTypography>
      </MDBox>
      <MDBox display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
          <MDButton
            onClick={() => handleClickOpen()}
            variant="outlined"
            color="primary"
            fullWidth
            sx={{ height: '100%', width: '100%' }}
          >
            delete account
          </MDButton>
        </MDBox>
      </MDBox>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <MDTypography alignItems="center" variant="button" color="text">
              Please be certain. Once you delete your account, there is no going
              back.
            </MDTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>Disagree</MDButton>
          <MDButton onClick={handleConfirmDelete} autoFocus>
            Agree
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
};

export default DeleteAccount;
