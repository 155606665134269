import checkout from './form';

const {
  formField: {
    firstName,
    lastName,
    email,
    repeatEmail,
    password,
    repeatPassword,
    idNumber,
    passportNumber,
  },
} = checkout;

export default {
  [firstName.name]: '',
  [lastName.name]: '',
  [email.name]: '',
  [repeatEmail.name]: '',
  [password.name]: '',
  [repeatPassword.name]: '',
  [idNumber.name]: '',
  [passportNumber.name]: '',
};
