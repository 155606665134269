// prop-type is a library for typechecking of props
import PropTypes from 'prop-types';

// formik components
import { ErrorMessage, Field } from 'formik';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// Pursuit components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';

export const PhoneField = ({ field, form, ...props }) => {
  return <PhoneInput {...field} {...props} country={'us'} />;
};

function FormField({ label, name, type, ...rest }) {
  return (
    <MDBox mb={1.5}>
      {type === 'phone' ? (
        <Field
          {...rest}
          name={name}
          component={PhoneField}
          variant="standard"
          label={label}
          fullWidth
          type={type}
        />
      ) : (
        <Field
          {...rest}
          name={name}
          as={MDInput}
          variant="standard"
          label={label}
          fullWidth
          type={type}
        />
      )}

      <MDBox mt={0.75}>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
        >
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormField;
