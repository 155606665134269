const timelineData = [
  {
    color: 'secondary',
    icon: 'ios_share',
    title: '(Click) Share Icon',
  },
  {
    color: 'primary',
    icon: 'add_boxed',
    title: '(Scroll down) Add to Home',
  },
  {
    color: 'info',
    icon: 'add',
    title: 'Add Pursuit icon on Home Screen',
    lastItem: true,
  },
];

export default timelineData;
