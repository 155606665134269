// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Pursuit components
import MDBox from "components/MDBox";

// Returning User page components
import FormField from "layouts/pages/users/new-user/components/FormField";

const ForgotPasswordForm = ({ formData }) => {
  const { formField, values, errors, touched } = formData;
  const { email } = formField;
  const { email: emailV } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <MDBox mb={2}>
          <FormField
            type={email.type}
            label={email.label}
            name={email.name}
            value={emailV}
            placeholder={email.placeholder}
            error={errors.email && touched.email}
            success={emailV.length > 0 && !errors.email}
          />
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

// typechecking props for ForgotPasswordForm
ForgotPasswordForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ForgotPasswordForm;
