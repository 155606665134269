import { useState } from 'react';

// @mui material components
import Container from '@mui/material/Container';

// General Components
import PageLayout from 'examples/LayoutContainers/PageLayout';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DefaultNavbar from '../../../examples/Navbars/DefaultNavbar';
import Content from './Content';

// Redux
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const AboutPage = () => {
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const token = user.data.token;

  if (location.pathname.includes('dashboard') && token) {
    return (
      <DashboardLayout>
        <DashboardNavbar userName />
        <Content />
      </DashboardLayout>
    );
  } else {
    return (
      <PageLayout>
        <Content />
      </PageLayout>
    );
  }
};

export default AboutPage;
