import checkout from "./form";

const {
  formField: { password, newPassword, repeatPassword },
} = checkout;

export default {
  [password.name]: "",
  [newPassword.name]: "",
  [repeatPassword.name]: "",
};
